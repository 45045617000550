import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@/graphql/fragments/UserFragment'

// Note: Do not add extra fields to this query.
//  It should be used to fetch user information in the lowest-latency fashion possible
export const GET_CURRENT_USER = gql`
    ${USER_FRAGMENT}
    query GetCurrentUser {
        currentUser {
            ...UserFragment
        }
    }
`
