import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLogPlatformEvent } from '../useLogPlatformEvent'
import { PlatformEventType } from '@/graphql/__generated__/globalTypes'
import { ROUTES } from '@/api/routes'
import { GET_CURRENT_USER } from '@/graphql/queries/user/GetCurrentUser'
import { type GetCurrentUser } from '@/graphql/__generated__/GetCurrentUser'

export interface LoginWithPopupSignature {
  loginWithPopUp: () => void
  isPopupOpen: boolean
  isLoading: boolean
}

/**
 * 1. Exposes a loginWithPopup method that initiates the Auth0 login UX
 * 2. Upon authenticating the user, redirects them to the root url
 * 3. Logs a USER_LOGIN PlatformEvent
 */
export function useLoginWithPopup (): LoginWithPopupSignature {
  const { data, loading: isLoading, error } = useQuery<GetCurrentUser>(
    GET_CURRENT_USER,
    { variables: {} }
  )
  const { isAuthenticated, loginWithPopup, isLoading: isPopupOpen } = useAuth0()
  const navigate = useNavigate()
  const { logEvent } = useLogPlatformEvent()

  function loginWithPopUpSafe (): void {
    void (async () => {
      await loginWithPopup()
    })()
  }

  useEffect(() => {
    if (isAuthenticated && data?.currentUser != null) {
      logEvent(PlatformEventType.USER_LOGIN)
      navigate(ROUTES.INDEX)
    }
  }, [isAuthenticated, data])

  return {
    loginWithPopUp: loginWithPopUpSafe,
    isPopupOpen,
    isLoading: isLoading && error == null
  }
}
