import { gql } from '@apollo/client'

export const AMPLIFY_ACCOUNT_FRAGMENT = gql`
    fragment AmplifyAccountFragment on AmplifyAccountFormatted {
        id
        status
        interestRate {
            rate
        }
        moneyMovementLimit {
            dailyAchCreditOriginationLimit {
                amount
            }
            dailyAchDebitOriginationLimit {
                amount
            }
            dailyWireOriginationLimit {
                amount
            }
        }
        bankPartner
    }
`
