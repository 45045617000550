import { gql } from '@apollo/client'
import { BANK_ACCOUNT_DOCUMENT_FRAGMENT } from '../fragments/financial_accounts/BankAccountDocumentFragment'

export const GET_BANK_ACCOUNT_DOCUMENTS = gql`
    ${BANK_ACCOUNT_DOCUMENT_FRAGMENT}
    query GetBankAccountDocuments(
        $accountId: Int!, 
        $type: BankDocumentType!,
        $startDate: String,
        $endDate: String
    ) {
        financialAccount(accountId: $accountId) {
            accountId
            bankDocuments(
                type: $type,
                startDate: $startDate,
                endDate: $endDate
            ) {
                ...BankAccountDocumentFragment
            }
        }
    }
`
