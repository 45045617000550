import React, { useEffect, useState, type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { type TransactionsQueryVariables } from '../TransactionsComponent'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import DownloadIcon from '@/library/icons/DownloadIcon'
import ErrorInline from '@/library/errors/ErrorInline'
import useInitiateBrowserDownload, { BlobDownloadFileType } from '@/hooks/useInitiateBrowserDownload'
import useFileExport from '@/hooks/useFileExport'

interface TransactionDownloadModalProps {
  isOpen: boolean
  onClose: () => void
  queryVariables?: TransactionsQueryVariables
  totalTransactionCount?: number
  organizationId: string
  isPageDataLoading?: boolean
}

export default function TransactionDownloadModal ({
  isOpen,
  onClose,
  queryVariables,
  totalTransactionCount,
  organizationId,
  isPageDataLoading
}: TransactionDownloadModalProps): ReactElement {
  const [downloadError, setDownloadError] = useState<Error>()
  const {
    createExportJob,
    fileDownloadUrl,
    loading: fileExportLoading
  } = useFileExport(
    {
      defaultExportJobMutationVariables: {
        transactionExportJobInput: {
          organizationId,
          sort: queryVariables?.sort,
          filter: queryVariables?.filter
        }
      },
      onError: error => { setDownloadError(error) }
    }
  )

  // Trigger Front End File Download
  const {
    setDownloadUrl,
    loading: localFileDownloadLoading
  } = useInitiateBrowserDownload({
    fileType: BlobDownloadFileType.CSV,
    filename: 'Altir Transactions Export.csv',
    onError: error => { setDownloadError(error) }
  })

  useEffect(() => {
    if (isOpen && downloadError == null) {
      createExportJob()
    }
  }, [isOpen])

  function handleClose (): void {
    onClose()
    setDownloadError(undefined)
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      size='sm'
    >
      <Flex flexDir='column' gap={8} alignItems='center' textAlign='center'>
        <Heading color={Color.DARK_BLUE} size='md'>
          Are you sure you want to download {totalTransactionCount} transactions?
        </Heading>
        <Flex flexDir='column' gap={4} w='100%'>
          <Button text='Download'
            beforeIcon={<DownloadIcon />}
            isLoading={localFileDownloadLoading || fileExportLoading || isPageDataLoading}
            onClick={() => { setDownloadUrl(fileDownloadUrl) }}
            isDisabled={downloadError != null}
          />
          <ErrorInline error={downloadError}/>
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={handleClose}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
