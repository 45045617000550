import React, { type ReactElement } from 'react'
import MailIcon from '@/library/icons/MailIcon'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { sendSupportEmail } from '@/library/nav/help_center/utils'
import { IconSize } from '@/theme/theme'

export default function TransferActionTag ({ status }: { status: TransferStatus | null }): ReactElement {
  if (status !== TransferStatus.ERROR) return <></>
  return (
    <Button
      text='Contact Us'
      beforeIcon={<MailIcon size={IconSize.SMALL}/>}
      width='auto'
      onClick={sendSupportEmail}
      variant={ButtonVariant.GREY}
      size={ButtonSize.SMALL}
    />
  )
}
