import React, { useState, type ReactElement } from 'react'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { useQuery } from '@apollo/client'
import { FormInput } from '@/library/form/text/FormInput'
import ErrorToast from '@/library/errors/ErrorToast'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { GET_ORGANIZATION_USERS } from '@/graphql/queries/business/GetOrganizationUsers'
import {
  type GetOrganizationUsers,
  type GetOrganizationUsersVariables,
  type GetOrganizationUsers_currentUser_selectedOrganization_users as User
} from '@/graphql/__generated__/GetOrganizationUsers'
import CheckBoxSelectGroup, { type SelectItem } from '@/library/form/checkbox/CheckBoxSelectGroup'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getFormattedFullName, isEmpty } from '@/utils/stringUtils'
import { getSelectedEntryIds } from '@/library/form/checkbox/utils'
import FormNumberInput from '@/library/form/number/FormNumberInput'
import { Color } from '@/theme/theme'

const MAX_NUMBER_OF_STORE_LOCATION_DIGITS = 5 // Equates to 99,999 locations

export interface BusinessCreationFormData {
  franchiseGroupName: string
  franchiseGroupNumberOfLocations?: string
  franchiseBrand: string
  selectedUsers?: number[]
}

export interface NonNullBusinessCreationFormData {
  franchiseGroupName: string
  franchiseGroupNumberOfLocations: number
  franchiseBrand: string
  selectedUsers: number[]
}

interface BusinessCreationFormProps {
  organizationId: string | null
  onSubmit: (formData: NonNullBusinessCreationFormData) => void
  submissionError?: string
  submitButtonText?: string
  isSubmissionLoading: boolean
  shouldAssignUserPermissions: boolean
}

export default function BusinessCreationForm ({
  organizationId,
  onSubmit,
  submissionError,
  submitButtonText = 'Continue',
  isSubmissionLoading,
  shouldAssignUserPermissions
}: BusinessCreationFormProps): ReactElement {
  const [userSelections, setUserSelections] = useState<Record<string, SelectItem>>({})
  const {
    loading: isUserDataLoading,
    error
  } = useQuery<GetOrganizationUsers, GetOrganizationUsersVariables>(
    GET_ORGANIZATION_USERS,
    {
      variables: { organizationId },
      onCompleted: (data) => {
        if (shouldAssignUserPermissions && organizationId != null) {
          const organizationUsers = data?.currentUser?.selectedOrganization?.users ?? []
          setUserSelections(createUserCheckboxOptions(organizationUsers))
        }
      }
    }
  )

  const [formData, setFormData] = useState<BusinessCreationFormData>({
    franchiseGroupName: '',
    franchiseGroupNumberOfLocations: undefined,
    franchiseBrand: ''
  })
  const [formError, setFormError] = useState<string | null>()

  function handleSubmission (): void {
    displayFormErrorsIfInvalid()
    if (
      isEmpty(formData.franchiseBrand) ||
      isEmpty(formData.franchiseGroupName) ||
      isEmpty(formData.franchiseGroupNumberOfLocations)
    ) {
      return
    }
    onSubmit({
      franchiseBrand: formData.franchiseBrand,
      franchiseGroupName: formData.franchiseGroupName,
      franchiseGroupNumberOfLocations: Number(formData.franchiseGroupNumberOfLocations),
      selectedUsers: getSelectedEntryIds(userSelections).map(id => Number(id))
    })
  }

  function isFormValid (): boolean {
    return !isEmpty(formData.franchiseBrand) &&
      !isEmpty(formData.franchiseGroupName) &&
      !isEmpty(formData.franchiseGroupNumberOfLocations)
  }

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    setFormError(null)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function onLocationCountChange (locationCount: string): void {
    setFormError(null)
    setFormData((prevFormData) => ({
      ...prevFormData,
      franchiseGroupNumberOfLocations: locationCount
    }))
  }

  function displayFormErrorsIfInvalid (): void {
    if (formData.franchiseGroupName === '') {
      setFormError('Please enter a name for your franchise')
    }
    if (formData.franchiseGroupNumberOfLocations == null) {
      setFormError('Please tell us how many locations are part of your group')
    }
    if (formData.franchiseBrand === '') {
      setFormError('Please select a brand for your business')
    }
  }

  return (
    <Flex flexDir='column' gap={8} w='100%'>
      <Stack spacing={5}>
        <FormInput
          fieldName='franchiseGroupName'
          value={formData.franchiseGroupName}
          label='Business Name'
          onChange={onChange}
          placeholder='i.e. Company, Inc.'
        />
        <FormNumberInput
          fieldName='franchiseGroupNumberOfLocations'
          value={formData.franchiseGroupNumberOfLocations}
          label='Number of Locations'
          onChange={onLocationCountChange}
          backgroundColor={Color.WHITE}
          placeholder='i.e. 5'
          maxNumberOfDigits={MAX_NUMBER_OF_STORE_LOCATION_DIGITS}
        />
        <FormInput
          fieldName='franchiseBrand'
          value={formData.franchiseBrand}
          label='Franchise Brand'
          onChange={onChange}
          placeholder='i.e. Burger King'
        />
        {shouldAssignUserPermissions &&
          <AltirSkeleton isLoading={isUserDataLoading} error={error}>
            <Flex flexDir='column' w='100%' alignItems='start' gap={4}>
              <Text>Select all users that can access the new business</Text>
              <CheckBoxSelectGroup
                options={userSelections}
                setOptions={(options) => { setUserSelections(options) }}
              />
            </Flex>
          </AltirSkeleton>
        }
        <ErrorToast errorMessage={formError ?? submissionError}/>
      </Stack>
      <Button
        text={submitButtonText}
        isDisabled={!isFormValid()}
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.MEDIUM}
        isLoading={isSubmissionLoading}
        onClickEventType={Event.FRANCHISE_CREATION_FORM_SUBMISSION_CLICK}
        onClick={handleSubmission}
      />
    </Flex>
  )
}

function createUserCheckboxOptions (users: User[]): Record<string, SelectItem> {
  return users.reduce<Record<string, SelectItem>>((record, user) => {
    record[`${user.id}`] = { description: getFormattedFullName(user.firstName, user.lastName), isSelected: true }
    return record
  }, {})
}
