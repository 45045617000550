import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Spacer } from '@chakra-ui/react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import VerticalNavBar from './VerticalNavBar'
import DisclosureFooter from './DisclosureFooter'
import HelpCenterPopoverComponent from './help_center/HelpCenterPopoverComponent'
import HorizontalNavBar from './horizontal_nav_bar/HorizontalNavBar'
import { Height, Color } from '../theme/theme'
import UserInactivityWarningComponent from '../utility/UserInactivityWarningComponent'
import { GET_NAV_CONTAINER_DATA } from '@/graphql/queries/GetNavContainerData'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { shouldShowDisclosureFooter } from '@/utils/routerUtils'
import { isErrorOfType } from '@/utils/errorUtils'
import { GraphQLErrorCode } from '@/graphql/__generated__/globalTypes'
import { ROUTES } from '@/api/routes'
import { SCROLL_CONTAINER_ID } from '@/utils/scrollUtils'
import { useScrollToTop } from '@/hooks/useScrollToTop'
import {
  type GetNavContainerData,
  type GetNavContainerDataVariables
} from '@/graphql/__generated__/GetNavContainerData'

export default function NavContainerLayout (): ReactElement {
  const location = useLocation()
  const navigate = useNavigate()
  useScrollToTop({})
  const {
    selectedOrganizationId
  } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId
    }
  })

  const [isVerticalNavBarExpanded, setIsVerticalNavBarExpanded] = useState(true)

  const { data, loading } = useQuery<GetNavContainerData, GetNavContainerDataVariables>(
    GET_NAV_CONTAINER_DATA,
    {
      variables: { organizationId: selectedOrganizationId },
      onError: (error) => {
        if (isErrorOfType(error, GraphQLErrorCode.AUTHORIZATION_ERROR, ['currentUser', 'selectedOrganization'])) {
          navigate(ROUTES.RESET_STATE)
        }
      },
      fetchPolicy: 'network-only'
    }
  )
  const shouldShowInActivityBlur = data?.currentUser?.rolloutConfiguration?.enableInActivityBlur === true

  return (
    <>
      <Flex
        height='100vh'
        bg={Color.LIGHT_GREY}
      >
        <VerticalNavBar
          currentUser={data?.currentUser}
          amplifyAccounts={data?.amplifyAccounts ?? []}
          isLoading={loading}
          isExpanded={isVerticalNavBarExpanded}
          onToggle={() => { setIsVerticalNavBarExpanded(!isVerticalNavBarExpanded) }}
        />
        <Flex
          overflowY='auto'
          paddingTop={Height.NAVBAR}
          width='100%'
          flexDir='column'
          justifyContent='start'
          alignItems='center'
          id={SCROLL_CONTAINER_ID}
        >
          <Flex
            alignItems='start'
            justifyContent='center'
            w='100%'
          >
            <Outlet/>
          </Flex>
          <Spacer/>
          {shouldShowDisclosureFooter(location) && <DisclosureFooter/>}
        </Flex>
      </Flex>
      <HorizontalNavBar currentUser={data?.currentUser} isVerticalNavBarExpanded={isVerticalNavBarExpanded}/>
      <HelpCenterPopoverComponent/>
      {shouldShowInActivityBlur && <UserInactivityWarningComponent/>}
    </>
  )
}
