import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'
import { USER_FRAGMENT } from '@/graphql/fragments/UserFragment'

export const GET_AUTHENTICATED_USER_WITH_CONTEXT = gql`
    ${AUTHORIZATION_FRAGMENT}
    ${USER_FRAGMENT}
    query GetAuthenticatedUserWithContext {
        currentUser {
            ...UserFragment
            authorization {
                ...AuthorizationFragment
            }
            selectedOrganization {
                id
                name
                franchiseGroups {
                    id
                    name
                }
            }
            isUserSignedUp
            onboardingStep
        }
    }
`
