import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import NavBackButton from './components/NavBackButton'
import NavBarLogoutMenu from './components/NavBarLogoutMenu'
import PageTitle from './components/PageTitle'
import {
  Height,
  Width,
  ZIndex,
  TransitionTime,
  FormInputHeight
} from '../../theme/theme'
import AltirProUpgradeComponent from '../../pro/AltirProUpgradeComponent'
import { AltirProUpgradeModalVariant } from '../../pro/AltirProUpgradeModal'
import OrganizationSelectorComponent from '../../state/OrganizationSelectorComponent'
import {
  type GetNavContainerData_currentUser as CurrentUser
} from '@/graphql/__generated__/GetNavContainerData'

const MotionFlex = motion(Flex)

const SIDE_PADDING_VARIANT = {
  COLLAPSED: { paddingLeft: `${Width.VERTICAL_NAVBAR_COLLAPSED_WIDTH_PIXELS - 30}px` },
  EXPANDED: { paddingLeft: `${Width.VERTICAL_NAVBAR_EXPANDED_WIDTH_PIXELS - 30}px` }
}

interface NavBarProps {
  currentUser?: CurrentUser | null
  isVerticalNavBarExpanded: boolean
}

export default function HorizontalNavBar (
  {
    currentUser,
    isVerticalNavBarExpanded
  }: NavBarProps
): ReactElement {
  const isStripeCheckoutEnabled = currentUser?.rolloutConfiguration?.enableStripeCheckout === true

  return (
    <Flex
      position='fixed'
      top={0}
      left={0}
      width='100%'
      height={Height.NAVBAR}
      backgroundColor='white'
      zIndex={ZIndex.NAV_SECONDARY}
      justifyContent='space-between'
      alignItems='center'
      boxShadow='0px 4px 30px rgba(0, 0, 0, 0.1)'
      pl={8}
      pr={4}
    >
      <MotionFlex
        as={motion.div}
        animate={isVerticalNavBarExpanded ? SIDE_PADDING_VARIANT.EXPANDED : SIDE_PADDING_VARIANT.COLLAPSED}
        variants={SIDE_PADDING_VARIANT}
        transition={{ duration: TransitionTime.NAV_TRANSITION }}
        initial={SIDE_PADDING_VARIANT.EXPANDED}
        gap={3}
        alignItems='center'
      >
        <NavBackButton />
        <PageTitle/>
      </MotionFlex>
      <Flex alignItems='center' gap={4}>
        {
          isStripeCheckoutEnabled &&
            <AltirProUpgradeComponent
              productVariant={AltirProUpgradeModalVariant.DASHBOARD}
              width='260px'
              ctaText='Upgrade to Altir Pro'
              subscriptionLevel={currentUser.selectedOrganization?.subscriptionLevel}
            />
        }
        <Flex alignItems='center'>
          <OrganizationSelectorComponent height={FormInputHeight.SMALL}/>
          <NavBarLogoutMenu/>
        </Flex>
      </Flex>
    </Flex>
  )
}
