import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import TransferCreationComponent from './components/TransferCreationComponent'
import RecentTransfersComponent from './components/transfer_history/RecentTransfersComponent'
import ViewAutomatedTransferRulesCard from './components/settings/ViewAutomatedTransferRulesCard'
import AmplifyInfoComponent from './components/amplify_info/AmplifyInfoComponent'
import RecipientsCard from './components/settings/recipients/RecipientsCard'
import CounterpartiesPendingVerificationCard from
  './components/settings/recipients/CounterpartiesPendingVerificationCard'
import CreatedAutomatedTransferButton from './components/settings/CreateAutomatedTransferButton'
import { ContainerWidth } from '@/theme/theme'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

export default function TransferPage (): ReactElement {
  return (
    <Center w='100%'>
      <Center gap={6} w='100%' maxW={ContainerWidth.FULL_PAGE} alignItems='start'>
        <Center w='100%' flexDir='column' gap='10' flex={3}>
          <TransferCreationComponent/>
          <RecentTransfersComponent/>
        </Center>
        <Flex flexDir='column' gap={6} flex={1}>
          <BusinessSelectorComponent
            businessFilterParams={{ hasAmplify: true }}
            requireMultipleBusinesses={true}
          />
          <Flex flexDir='column' gap={3}>
            <Text>Transfer Settings</Text>
            <ViewAutomatedTransferRulesCard/>
            <AmplifyInfoComponent/>
            <RecipientsCard/>
            <CounterpartiesPendingVerificationCard />
            <CreatedAutomatedTransferButton/>
          </Flex>
        </Flex>
      </Center>
    </Center>
  )
}
