import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'
import { USER_FRAGMENT } from '@/graphql/fragments/UserFragment'

export const GET_USER_AUTHORIZATION = gql`
    ${AUTHORIZATION_FRAGMENT}
    ${USER_FRAGMENT}
    query GetUserAuthorization($organizationId: String) {
        currentUser {
            ...UserFragment
            authorization(organizationId: $organizationId) {
                ...AuthorizationFragment
            }
        }
    }
`
