import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@/graphql/fragments/UserFragment'

export const GET_ADD_NEW_BUSINESS_MODAL_DATA = gql`
    ${USER_FRAGMENT}
    query GetAddNewBusinessModalData($organizationId: String) {
        currentUser {
            ...UserFragment
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                franchiseGroups {
                    id
                    name
                }
            }
        }
    }
`
