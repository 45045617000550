import React, { useEffect, useRef, useCallback, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Duration } from 'luxon'
import BackgroundBlurComponent from './BackgroundBlurComponent'
import Button, { ButtonVariant } from '../button/Button'
import ModalCloseHeader from '../modal/ModalCloseHeader'
import { useIsUserIdleUntilReset } from '@/hooks/state/useIdleUserState'
import { BorderRadius, Color } from '@/theme/theme'

const INNACTIVITY_TIMEOUT_SECONDS = 60 * 4
const AUTOLOGOUT_TIMEOUT_SECONDS = 60 * 1

interface UserInactivityBlurOverlayComponentProps {
  children?: ReactElement
}

export default function UserInactivityWarningComponent (
  { children }:
  UserInactivityBlurOverlayComponentProps): ReactElement {
  const { isUserIdle, resetIdleUserState } = useIsUserIdleUntilReset({ timeoutSeconds: INNACTIVITY_TIMEOUT_SECONDS })
  const autoLogoutTimer = useRef<NodeJS.Timeout | null>(null)
  const { logout } = useAuth0()
  const client = useApolloClient()
  const inactivityTimeoutMinutes =
    Duration.fromObject({ seconds: INNACTIVITY_TIMEOUT_SECONDS })
      .as('minutes')
      .toFixed(0)

  const logoutAndResetCache = useCallback((): void => {
    logout({ logoutParams: { returnTo: window.location.origin } })
    client.cache.reset().catch(() => { console.log('Failed to reset cache') })
  }, [client.cache, logout])

  useEffect(() => {
    window.addEventListener('beforeunload', () => { logoutAndResetCache() })
    return () => {
      window.removeEventListener('beforeunload', () => { logoutAndResetCache() })
    }
  }, [logoutAndResetCache])

  useEffect(() => {
    if (isUserIdle) {
      autoLogoutTimer.current = setTimeout(() => {
        logoutAndResetCache()
      }, AUTOLOGOUT_TIMEOUT_SECONDS * 1000)
    } else if (autoLogoutTimer.current != null) {
      clearTimeout(autoLogoutTimer.current)
    }
  }, [isUserIdle, logoutAndResetCache])

  function handleClose (): void {
    if (autoLogoutTimer.current != null) {
      clearTimeout(autoLogoutTimer.current)
    }
    resetIdleUserState()
  }

  return (
    <BackgroundBlurComponent isVisible={isUserIdle}>
      <Flex
        position='relative'
        flexDir='column'
        bg={Color.WHITE}
        borderRadius={BorderRadius.CARD}
        alignItems='center'
        my='auto'
        mx='auto'
        p={8}
      >
        {/* Add Close Icon */}
        <Flex position='absolute' top={0} right={0} m={4}><ModalCloseHeader onClose={handleClose} /></Flex>

        <Flex flexDir='column' gap={6} align='center' >
          <Flex flexDir='column' gap={2} align='center'>
            <Heading size='md'>Stay Logged In?</Heading>
            <Text align='center'>
              You have been inactive for more than {inactivityTimeoutMinutes} minutes.
              <br />
              Would you like to stay logged in?
            </Text>
          </Flex>
          <Flex flexDir='column' gap={4} w='100%'>
            <Button
              variant={ButtonVariant.GREY}
              text='Stay Logged In'
              onClick={() => { handleClose() }}
            />
            <Button
              variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
              text='Log Out'
              onClick={() => { logoutAndResetCache() }}
            />
          </Flex>
        </Flex>
      </Flex>
    </BackgroundBlurComponent>
  )
}
