import { type MenuLinkObject } from './menu_item/VerticalNavMenuItem'
import { RouteName } from '@/api/routes'
import {
  type GetNavContainerData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetNavContainerData'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'

export function getCardsMenuSubFields (
  businesses: Business[],
  { isCapitalOsEnabled }: { isCapitalOsEnabled: boolean }
): MenuLinkObject[] {
  if (!isCapitalOsEnabled) return []

  const businessesWithCardPrograms = businesses
    .filter(business => {
      const accountStatus = business.capitalOSConfiguration?.account?.status
      if (accountStatus == null) return false

      return [
        CapitalOSAccountStatus.ELIGIBLE,
        CapitalOSAccountStatus.ONBOARDING,
        CapitalOSAccountStatus.PENDING,
        CapitalOSAccountStatus.PROCESSING,
        CapitalOSAccountStatus.APPROVED
      ].includes(accountStatus)
    })

  return businessesWithCardPrograms
    .map(business => {
      return {
        text: business.name ?? '',
        link: `/${RouteName.CAPTIAL_OS_CARDS}/${business.id}`
      }
    })
}
