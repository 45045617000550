import { Center, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AccountDocumentsComponent from './documents/AccountDocumentsComponent'
import AccountTransactionsComponent from './AccountTransactionsComponent'
import { ROUTES } from '@/api/routes'
import FinancialAccountDetailComponent from '@/library/accounts/FinancialAccountDetailComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import TransferIcon from '@/library/icons/TransferIcon'
import ListIcon from '@/library/icons/ListIcon'
import { IconSize } from '@/theme/theme'
import {
  AccountLinkStatus,
  InstitutionConnectionProvider
} from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_FINANCIAL_ACCOUNT } from '@/graphql/queries/accounts/GetFinancialAccount'
import {
  type GetFinancialAccount,
  type GetFinancialAccountVariables
} from '@/graphql/__generated__/GetFinancialAccount'
import FinancialAccountBalanceDisplayComponent from '@/library/accounts/FinancialAcountBalanceDisplayComponent'

export interface TreasuryDetailComponentProps {
  accountId: number
}

export default function AccountStatementComponent (
  {
    accountId
  }: TreasuryDetailComponentProps
): ReactElement<string, string> {
  const navigate = useNavigate()
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNT,
    { variables: { accountId } }
  )

  const account = data?.financialAccount
  const isAmplifyAccount = account?.amplifyAccount?.id != null
  const needsPlaidReLink = account?.connectionProvider === InstitutionConnectionProvider.PLAID &&
    data?.financialAccount?.status !== AccountLinkStatus.HEALTHY
  // TODO PAL: Fix queries to ensure the reLink banner can directly fix link
  const plaidAccessToken = account?.plaidAccessToken ?? undefined
  const institutionName = account?.institution?.name ?? undefined

  // Balances
  const availableBalance = account?.liveBalance?.availableBalance?.amount ?? null
  const currentBalance = account?.liveBalance?.currentBalance?.amount ?? null

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={6} w='100%'>
        {needsPlaidReLink &&
          <ReLinkErrorBanner
            franchiseGroupId={franchiseGroupId}
            plaidAccessToken={plaidAccessToken}
            refetchData={refetch}
            institutionName={institutionName}
          />
        }
        <Flex
          width='100%'
          flexDirection='row'
          gap={6}
        >
          <Flex
            flexDirection='column'
            flex={1}
            gap={6}
          >
            <FinancialAccountBalanceDisplayComponent
              availableBalance={availableBalance}
              currentBalance={currentBalance}
            />
            <FinancialAccountDetailComponent accountId={accountId}/>
            {isAmplifyAccount && <AccountDocumentsComponent accountId={accountId}/>}
            <Flex flexDir='column' gap={4}>
              <Button
                text='Transfer'
                onClick={() => { navigate(ROUTES.TRANSFER) }}
                beforeIcon={<TransferIcon/>}
                size={ButtonSize.MEDIUM}
                onClickEventType={Event.OPEN_TRANSFER_PAGE_CLICK}
              />
              <Button
                text='Transfer History'
                onClick={() => { navigate(ROUTES.TRANSFER_HISTORY) }}
                beforeIcon={<ListIcon size={IconSize.SMALL}/>}
                variant={ButtonVariant.WHITE_OUTLINE}
                onClickEventType={Event.VIEW_ALL_TRANSFERS_CLICK}
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection='column'
            flex={2}
            alignItems='start'
            gap={3}
            w='100%'
          >
            <Text>Transaction History</Text>
            <AccountTransactionsComponent accountId={accountId}/>
          </Flex>
        </Flex>
      </Center>
    </AltirSkeleton>
  )
}
