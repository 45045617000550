import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../fragments/AuthorizationFragment'
import { FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_TREASURY_PAGE_DATA = gql`
    ${AUTHORIZATION_FRAGMENT}
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetTreasuryPageData($organizationId: String!, $franchiseGroupId: Int!, $aggregateBalanceAccountFilter:FinancialAccountFilterInput, $financialAccountFilter: FinancialAccountFilterInput) {
        currentUser {
            authorization(franchiseGroupId: $franchiseGroupId, organizationId: $organizationId) {
                ...AuthorizationFragment
            }
            rolloutConfiguration {
                enableCapitalOS
                enableTransactionRefresh
            }
            selectedOrganization(organizationId: $organizationId) {
                id
                selectedFranchiseGroup(franchiseGroupId: $franchiseGroupId) {
                    id
                    hasBrokenAccountLink
                    amplifyAccount {
                        accountId
                    }
                    amplifyAccountApplicationStatus
                    aggregateAccountBalance(financialAccountFilter: $aggregateBalanceAccountFilter) {
                        availableBalance {
                            amount
                        }
                        currentBalance {
                            amount
                        }
                    }
                    financialAccounts(financialAccountFilter: $financialAccountFilter) {
                        ...FinancialAccountExtendedFragment
                    }
                }
            }
            capitalOsConfiguration(franchiseGroupId: $franchiseGroupId) {
                account {
                    currentBalance {
                        amount
                    }
                }
            }
        }
    }
`

export const GET_AMPLIFY_ACCOUNT_CARD_DATA = gql`
    ${AUTHORIZATION_FRAGMENT}
    query GetAmplifyAccountCardData($organizationId: String!, $franchiseGroupId: Int!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                subscriptionLevel
                isOnAmplifyWaitlist
                franchiseGroups {
                    amplifyAccountApplicationStatus
                }
            }
            franchiseGroup(id: $franchiseGroupId) {
                amplifyAccountApplicationStatus
            }
            authorization(organizationId: $organizationId, franchiseGroupId: $franchiseGroupId) {
                ...AuthorizationFragment
            }
        }
        amplifyAccount(franchiseGroupId: $franchiseGroupId) {
            accountId
            name
            liveBalance {
                availableBalance {
                    amount
                }
            }
            amplifyAccount {
                earningsToDate {
                    amount
                }
                interestRate {
                    rate
                }
                bankPartner
            }
            achAccountNumber
            achRoutingNumber
        }
        amplifyAccountPromotionalOffer {
            potentialYearlyEarningsFormatted
            subscribedAnnualPercentageYield
            unsubscribedAnnualPercentageYield
        }
    }
`
