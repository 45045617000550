import { gql } from '@apollo/client'
import { COUNTERPARTY_FRAGMENT } from './CounterpartyFragment'
import { AMPLIFY_ACCOUNT_FRAGMENT } from './AmplifyAccountFragment'
import { FINANCIAL_ACCOUNT_FRAGMENT } from './FinancialAccountFragment'
import { TRANSFER_RULE_FRAGMENT } from '../TransferRuleFrament'
import { TRACKED_JOB_FRAGMENT } from '../TrackedJobFragment'

export const FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT = gql`
    ${TRANSFER_RULE_FRAGMENT}
    ${TRACKED_JOB_FRAGMENT}
    ${COUNTERPARTY_FRAGMENT}
    ${AMPLIFY_ACCOUNT_FRAGMENT}
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    fragment FinancialAccountExtendedFragment on FinancialAccount {
        ...FinancialAccountFragment
        transferRules {
            ...TransferRuleFragment
        }
        liveBalance {
            availableBalance {
                amount
                currency
            }
            currentBalance {
                amount
                currency
            }
            updatedAt
        }
        defaultStoreLocation{
            id
            storeId
            name
        }
        business {
            name
        }
    }
`
