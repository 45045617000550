import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import DocumentRow from './DocumentRow'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  BorderRadius,
  Color
} from '@/theme/theme'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import { GET_BANK_ACCOUNT_DOCUMENTS } from '@/graphql/queries/GetBankAccountDocuments'
import {
  type GetBankAccountDocuments,
  type GetBankAccountDocumentsVariables
} from '@/graphql/__generated__/GetBankAccountDocuments'
import { BankDocumentType } from '@/graphql/__generated__/globalTypes'
import { type BankAccountDocumentFragment } from '@/graphql/__generated__/BankAccountDocumentFragment'
import { DateTimeTemplate, getFormattedDateString, TimeZone } from '@/utils/dateUtils'
import { nonNull, unique } from '@/utils/arrayUtils'

export interface AccountActivityComponentProps {
  accountId: number
}

export default function AccountDocumentsComponent (
  {
    accountId
  }: AccountActivityComponentProps
): ReactElement<string, string> {
  const [yearSelectionOptions, setYearSelectionOptions] = useState<string[]>([])
  const [selectedYear, setSelectedYear] = useState<string>()
  const {
    data,
    loading,
    error
  } = useQuery<GetBankAccountDocuments, GetBankAccountDocumentsVariables>(
    GET_BANK_ACCOUNT_DOCUMENTS,
    {
      variables: {
        accountId,
        type: BankDocumentType.STATEMENT
      },
      onCompleted: (data) => {
        const yearOptions = getYearOptions(data.financialAccount.bankDocuments ?? [])
        setYearSelectionOptions(yearOptions)
        setSelectedYear(yearOptions[yearOptions.length - 1])
      }
    }
  )

  const allStatements = data?.financialAccount.bankDocuments ?? []
  const displayedStatements = filterStatements(allStatements as BankAccountDocumentFragment[], selectedYear)

  return (
    <Flex flexDirection='column'>
      <Text>Documents</Text>
      <AltirSkeleton isLoading={loading} error={error} marginY={3}>
        <Flex
          width='100%'
          flexDirection='column'
          p={6}
          borderRadius={BorderRadius.CARD}
          bg={Color.WHITE}
          gap={6}
        >
          <Flex
            justifyContent='space-between'
            gap={4}
          >
            <Flex flexDir='column'>
              <Text color={Color.DARK_BLUE}>Statements</Text>
              <Text>Download monthly account statements</Text>
            </Flex>
            <Flex>
              <PopoverMenu
                popoverMenuTitle={selectedYear ?? 'Select Year'}
                onSubmit={() => {}}
                border={`1px solid ${Color.DARK_BLUE}`}
                borderRadius={BorderRadius.BUTTON}
                shouldMatchWidth={true}
                allowInteriorScroll={true}
              >
                <PopoverMenuSingleOptionSelect
                  options={yearSelectionOptions}
                  setSelectedOptionOrUndefined={(year) => { setSelectedYear(year) }}
                  selectedOption={selectedYear}
                  formattingFunction={(year) => { return { title: year } }}
                />
              </PopoverMenu>
            </Flex>
          </Flex>
          <Flex flexDir='column' gap={4}>
            {displayedStatements.map((statement, index) => {
              return (
                <Flex key={index} flexDir='column' gap={4}>
                  <DocumentRow document={statement}/>
                  {index !== displayedStatements.length - 1 && <Divider/>}
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Flex>
  )
}

function filterStatements (
  statements: BankAccountDocumentFragment[],
  selectedYear: string | undefined
): BankAccountDocumentFragment[] {
  if (selectedYear == null) return statements

  return statements.filter(statement => {
    const statementYear = getFormattedDateString(
      statement.documentStartPeriod,
      DateTimeTemplate.YEAR_LONG,
      TimeZone.DEFAULT_SYSTEM_TIME_ZONE
    )
    return statementYear === selectedYear
  })
}

function getYearOptions (statements: BankAccountDocumentFragment[]): string[] {
  return unique(
    nonNull(
      statements.map(statement => getFormattedDateString(
        statement.documentStartPeriod, DateTimeTemplate.YEAR_LONG, TimeZone.DEFAULT_SYSTEM_TIME_ZONE
      ))
    )
  )
}
