import React, { useState, type ReactElement } from 'react'
import { Flex, Radio, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import AccountDisclosuresComponent from './AccountDisclosuresComponent'
import ErrorInline from '@/library/errors/ErrorInline'
import Button from '@/library/button/Button'

interface DisclosuresAgreementComponentProps {
  onSubmit: () => void
  isLoading: boolean
  error?: Error
}

export default function DisclosuresFormComponent ({
  onSubmit,
  isLoading,
  error
}: DisclosuresAgreementComponentProps): ReactElement {
  const [hasVerifiedAgreement, setHasVerifiedAgreement] = useState(false)
  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <AccountDisclosuresComponent/>
      <Flex gap={4}>
        <Radio
          isChecked={hasVerifiedAgreement}
          onClick={() => { setHasVerifiedAgreement(!hasVerifiedAgreement) }}
          size='lg'
          colorScheme='selectableInput'
        />
        <Text>
          I have read and agree to the above documents.*
        </Text>
      </Flex>
      <Flex flexDir='column' gap={4}>
        <Button
          text='Agree & Sign'
          isDisabled={!hasVerifiedAgreement}
          onClick={onSubmit}
          isLoading={isLoading}
          onClickEventType={Event.APPLICATION_DISCLOSURES_ACCEPTANCE_CLICK}
        />
        <ErrorInline error={error}/>
      </Flex>
      <Text fontSize='sm'>
        *Checking this box serves as consent to use electronic records and electronic signatures
        in accordance with the ESIGN Act.
      </Text>
    </Flex>
  )
}
