import {
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Radio,
  Text
} from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import NewExternalRecipientCard from './NewExternalRecipientCard'
import TransferFeeLineItem from './TransferFeeLineItem'
import Button, { ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { type TransferType } from '@/graphql/__generated__/globalTypes'
import InstitutionLogo from '@/library/logo/InstitutionLogo'
import {
  getCounterpartyLongName,
  isLinkedBankAccountOrAmplifyAccount
} from '@/utils/financialAccountUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { getTransferComponentContent } from '@/utils/transferUtils'
import VerticalDivider, { VerticalDividerHeight } from '@/library/divider/VerticalDivider'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export interface ConfirmTransferModalProps {
  isOpen: boolean
  onClose: () => void
  amount: number
  fromAccount?: FinancialAccountExtendedFragment
  toAccount?: FinancialAccountExtendedFragment
  onConfirm: () => void
  isTransferCreationLoading: boolean
  transferType: TransferType
}

export function ConfirmTransferModal ({
  isOpen,
  onClose,
  amount,
  fromAccount,
  toAccount,
  onConfirm,
  isTransferCreationLoading,
  transferType
}: ConfirmTransferModalProps): ReactElement {
  const isUnverifiedExternalCounterparty =
    toAccount?.counterparty != null && !toAccount.counterparty.isOwnershipVerified
  const fromBalanceFormatted = getCurrencyFormatted(fromAccount?.liveBalance?.availableBalance?.amount)
  const toBalanceFormatted = getCurrencyFormatted(toAccount?.liveBalance?.availableBalance?.amount)

  const fromAccountIsLinkedBankAccount = fromAccount != null && isLinkedBankAccountOrAmplifyAccount(fromAccount)
  const toAccountIsLinkedBankAccount = toAccount != null && isLinkedBankAccountOrAmplifyAccount(toAccount)
  const {
    transferTotal,
    agreementUrl,
    transferTypeFormatted,
    transferFee
  } = getTransferComponentContent(amount, transferType)
  const amountFormattedString = getCurrencyFormatted(Number(amount), { minimumFractionDigits: 2 })
  const totalFormattedString = getCurrencyFormatted(Number(transferTotal), { minimumFractionDigits: 2 })

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <Center flexDir='column' w='100%' gap={6}>
        <Heading size='lg'>Confirm Transfer</Heading>
        <Heading size='2xl'>{amountFormattedString}</Heading>
        <Flex
          flexDirection='column'
          width='100%'
          borderRadius={BorderRadius.CARD}
          gap={8}
        >

          <Flex gap={4}>
            <Flex direction='column' alignItems='center' gap={3}>
              <Flex
                borderRadius='100%'
                backgroundColor={Color.WHITE}
                py={3}
                px={3}
              >
                <InstitutionLogo
                  src={fromAccount?.institution?.logoAssetUrl ?? undefined}
                  height='20px'
                />
              </Flex>
              <VerticalDivider height={VerticalDividerHeight.FULL}/>
              <Flex
                borderRadius='100%'
                backgroundColor={Color.WHITE}
                py={3}
                px={3}
              >
                <InstitutionLogo
                  src={toAccount?.institution?.logoAssetUrl ?? undefined}
                  height='20px'
                />
              </Flex>
            </Flex>
            <Flex flexDirection='column' alignItems='start'>
              <Text fontSize='sm' color={Color.DARK_BLUE}>FROM</Text>
              <Text>{getCounterpartyLongName(fromAccount)} ***{fromAccount?.lastFour}</Text>
              {fromAccountIsLinkedBankAccount && <Text>Balance: {fromBalanceFormatted}</Text>}
              <Text mt={6} fontSize='sm' color={Color.DARK_BLUE}>TO</Text>
              <Text>{getCounterpartyLongName(toAccount)} ***{toAccount?.lastFour}</Text>
              {toAccountIsLinkedBankAccount && <Text>Balance: {toBalanceFormatted}</Text>}
            </Flex>
          </Flex>
          <Grid
            padding={6}
            gap={1}
            templateColumns='repeat(2, minmax(0, 1fr))'
            backgroundColor={Color.WHITE}
            borderRadius={BorderRadius.CARD}
            alignItems='center'
          >
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>
                Method
              </Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{transferTypeFormatted}</Text></GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>Amount</Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{amountFormattedString}</Text></GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>Fee</Text>
            </GridItem>
            <TransferFeeLineItem transferFee={transferFee}/>
            <GridItem colStart={1} colEnd={3}>
              <Divider my={2} color={Color.GREY}/>
            </GridItem>
            <GridItem justifySelf='start'>
              <Text color={Color.DARK_GREY}>
                Total Withdrawn
              </Text>
            </GridItem>
            <GridItem justifySelf='end'>
              <Text color={Color.DARK_BLUE}>{totalFormattedString}</Text></GridItem>
          </Grid>
          {isUnverifiedExternalCounterparty && <NewExternalRecipientCard/>}
          <Flex gap={4}>
            <Radio isChecked size='lg' colorScheme='selectableInput'/>
            <Flex flexDirection='column'>
              <Text>I have read and understand the</Text>
              <Link href={agreementUrl} isExternal>
                <Text
                  color={Color.BRIGHT_BLUE}
                  fontWeight={FontWeight.BOLD}
                  textDecoration='underline'
                  _hover={{ color: Color.BRIGHT_BLUE_HOVER }}
                >
                  {transferTypeFormatted} Authorization Agreement
                </Text>
              </Link>
            </Flex>
          </Flex>
          <Flex flexDirection='column' gap={3}>
            <Flex>
              <Button
                text='Confirm'
                onClick={onConfirm}
                isLoading={isTransferCreationLoading}
                isDisabled={isTransferCreationLoading}
                onClickEventType={Event.CONFIRM_TRANSFER_CLICK}
              />
            </Flex>
            <Button
              text='Go Back'
              onClick={onClose}
              variant={ButtonVariant.WHITE}
            />
          </Flex>
        </Flex>
      </Center>
    </ModalComponent>
  )
}
