import React, { type ReactElement } from 'react'
import FormNumberInput, { type NumberInputSizeVariant } from './FormNumberInput'
import { formatInputAsCurrency, parseCurrency, parsePositiveCurrency } from './utils'
import { type BaseFormInputProps } from '../types'

const DEFAULT_MAX_DIGITS = 8 // Corresponds to max value of $99,999,999

type FormDollarInputProps = {
  value: string
  onChange: (value: string) => void
  allowNegative?: boolean
  variant?: NumberInputSizeVariant
  maximumNumberOfDigits?: number
} & BaseFormInputProps

export default function FormDollarInput ({
  allowNegative = false,
  maximumNumberOfDigits = DEFAULT_MAX_DIGITS,
  ...props
}: FormDollarInputProps): ReactElement {
  return (
    <FormNumberInput
      autoFormatRule={formatInputAsCurrency}
      parseFormattedValue={allowNegative ? parseCurrency : parsePositiveCurrency}
      maxNumberOfDigits={maximumNumberOfDigits}
      {...props}
    />
  )
}
