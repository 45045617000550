import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Graphic } from '@/library/utility/Graphic'
import { ERROR_OCTAGON_GRAPHIC } from '@/theme/graphics'
import { BorderRadius, Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import BusinessCreationForm, {
  type NonNullBusinessCreationFormData
} from '@/components/pages/franchise_sign_up/BusinessCreationForm'
import { CREATE_FRANCHISE_GROUP } from '@/graphql/mutations/CreateFranchiseGroup'
import {
  type CreateFranchiseGroup,
  type CreateFranchiseGroupVariables
} from '@/graphql/__generated__/CreateFranchiseGroup'
import { getSuccessToast } from '@/utils/toastUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { getFranchiseGroupIds } from '@/utils/franchiseGroupUtils'
import { GET_ADD_NEW_BUSINESS_MODAL_DATA } from '@/graphql/queries/GetAddNewBusinessModalData'
import {
  type GetAddNewBusinessModalData,
  type GetAddNewBusinessModalDataVariables
} from '@/graphql/__generated__/GetAddNewBusinessModalData'

interface AddNewBusinessModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function AddNewBusinessModal ({
  isOpen,
  onClose
}: AddNewBusinessModalProps): ReactElement {
  const toast = useToast()
  const {
    selectedOrganizationId,
    setSelectedOrganizationState
  } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      availableFranchiseGroupIds: state.selectedOrganizationState.availableFranchiseGroupIds,
      setSelectedOrganizationState: state.setSelectedOrganizationState
    }
  })
  const [submissionError, setSubmissionError] = useState<string | undefined>()

  const [
    getCurrentUserAndUpdateStore,
    { loading: isUserDataFetchLoading }
  ] = useLazyQuery<GetAddNewBusinessModalData, GetAddNewBusinessModalDataVariables>(
    GET_ADD_NEW_BUSINESS_MODAL_DATA,
    {
      fetchPolicy: 'network-only',
      variables: { organizationId: selectedOrganizationId },
      onCompleted: (data) => {
        const franchiseGroupIds = getFranchiseGroupIds(data.currentUser?.selectedOrganization?.franchiseGroups ?? [])
        setSelectedOrganizationState({
          selectedOrganizationId,
          availableFranchiseGroupIds: franchiseGroupIds
        })
        onClose()
      }
    }
  )

  const [
    createBusiness,
    {
      loading: isCreateBusinessMutationLoading,
      client
    }
  ] = useMutation<CreateFranchiseGroup, CreateFranchiseGroupVariables>(
    CREATE_FRANCHISE_GROUP,
    {
      onCompleted: () => {
        toast(getSuccessToast('Successfully created business.'))
        void client.resetStore()
        void getCurrentUserAndUpdateStore({ variables: { organizationId: selectedOrganizationId } })
      },
      onError: () => {
        setSubmissionError('Unable to create business')
      }
    }
  )

  function handleFormSubmission (formData: NonNullBusinessCreationFormData): void {
    void createBusiness({
      variables: {
        organizationId: selectedOrganizationId,
        franchiseGroupName: formData.franchiseGroupName,
        franchiseBrand: formData.franchiseBrand,
        numberOfLocations: Number(formData.franchiseGroupNumberOfLocations),
        memberIds: formData.selectedUsers ?? []
      }
    })
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex flexDir='column' justifyContent='center' alignItems='center' w='100%' gap={8}>
        <Heading>Add a New Business</Heading>
        <Flex bg={Color.WHITE} borderRadius={BorderRadius.CARD} p={4} alignItems='center' gap={4}>
          <Graphic src={ERROR_OCTAGON_GRAPHIC} h='50px'/>
          <Flex flexDir='column' gap={1}>
            <Heading size='md'>Only add a new business if...</Heading>
            <Text>
              Only create a new business if you own a business with a valid EIN number and want to keep its
              finances separate from your existing business.
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir='column' w='100%' gap={4}>
          <BusinessCreationForm
            organizationId={selectedOrganizationId}
            onSubmit={handleFormSubmission}
            submissionError={submissionError}
            isSubmissionLoading={isCreateBusinessMutationLoading || isUserDataFetchLoading}
            submitButtonText='Create a New Business'
            shouldAssignUserPermissions={true}
          />
          <Button text='Cancel' variant={ButtonVariant.WHITE}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
