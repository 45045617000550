import React, { useEffect, useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Radio, Text } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AccountSelectorComponent, {
  AccountSelectorAccountContext,
  AccountSelectorContext
} from './account_selector/AccountSelectorComponent'
import { ConfirmTransferModal } from './confirmation_modal/ConfirmTransferModal'
import { TransferSuccessComponent } from './TransferSuccessComponent'
import AddWireCounterpartyModal from './settings/recipients/AddWireCounterpartyModal'
import TransferMethodSelector from './form/method_selector/TransferMethodSelector'
import CheckIssuanceFormSection, { type CheckIssuanceFormState } from './form/checks/CheckIssuanceFormSection'
import TransferAmountField from './form/amount_field/TransferAmountField'
import RecurringRuleFormSection, { type RecurringRuleFormState }
  from '../../transfer_rules/components/RecurringRuleFormSection'
import TransferRuleConfirmationModal from '../../transfer_rules/components/TransferRuleConfirmationModal'
import {
  type FinancialAccountExtendedFragment as FinancialAccount
} from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import {
  isAmplifyAccount,
  isCounterparty,
  needsWireVerification
} from '@/utils/transferUtils'
import { getCurrentDate } from '@/utils/dateUtils'
import { createInsufficientFundsErrorMessage, getAsyncTransferErrorMessage } from '@/utils/errorUtils'
import ErrorBanner from '@/library/errors/ErrorBanner'
import Button, { ButtonSize } from '@/library/button/Button'
import TransferIconFilled from '@/library/icons/TransferIconFilled'
import { Color } from '@/theme/theme'
import { CREATE_TRANSFER } from '@/graphql/mutations/CreateTransfer'
import {
  type CheckEnablementStatus,
  CounterpartyType,
  TransferDirection,
  TransferRuleType,
  TransferType
}
  from '@/graphql/__generated__/globalTypes'
import { type CreateTransferVariables, type CreateTransfer } from '@/graphql/__generated__/CreateTransfer'
import PasscodeVerificationModal from '@/library/modal/passcode/PasscodeVerificationModal'
import { isCounterpartyTypeLinkedBankAccount } from '@/utils/financialAccountUtils'
import { useNavigationState } from '@/hooks/useNavigationState'
import PaperPlaneIcon from '@/library/icons/PaperPlaneIcon'
import { nonNull } from '@/utils/arrayUtils'

interface TransferPageNavigationState {
  recipientCounterpartyId?: string
  isCheckSendPreSelected?: boolean
}

export interface TransferCreationFormComponentProps {
  amplifyAccount?: FinancialAccount
  counterparties?: FinancialAccount[]
  isRecurringVendorPaymentsEnabled: boolean
  isCheckIssuanceEnabled: boolean
  organizationCheckEnablementStatus?: CheckEnablementStatus
  refetch: () => void
}

export default function TransferCreationFormComponent (
  {
    amplifyAccount,
    counterparties,
    isRecurringVendorPaymentsEnabled,
    isCheckIssuanceEnabled,
    organizationCheckEnablementStatus,
    refetch
  }: TransferCreationFormComponentProps
): ReactElement {
  const [
    createTransfer,
    { loading: isTransferCreationLoading }
  ] = useMutation<CreateTransfer, CreateTransferVariables>(
    CREATE_TRANSFER, {
      onCompleted: handleTransferSuccess,
      onError: (error) => { handleAsyncTransferError(error) }
    }
  )

  // If url param contains counterpartyId, set as CREDIT and initialize component with CP selected
  const stateOnNavigate = useNavigationState<TransferPageNavigationState>()
  const [
    preSelectedRecipientCounterpartyId,
    setPreSelectedRecipientCounterpartyId
  ] = useState(stateOnNavigate?.recipientCounterpartyId)
  const [selectedSourceAccount, setSelectedSourceAccount] = useState<FinancialAccount | null>(
    preSelectedRecipientCounterpartyId != null ? (amplifyAccount ?? null) : null
  )
  const [selectedDestinationAccount, setSelectedDestinationAccount] = useState<FinancialAccount | null>(
    counterparties?.find(cp => cp.counterpartyId === preSelectedRecipientCounterpartyId) ?? null
  )

  useEffect(() => {
    setSelectedDestinationAccount(
      counterparties?.find(cp => cp.counterpartyId === preSelectedRecipientCounterpartyId) ?? null
    )
  }, [counterparties, preSelectedRecipientCounterpartyId])

  const [transferError, setTransferError] = useState<string | null>(null)
  const [amount, setAmount] = useState<string>()
  const [isRecurringTransfer, setIsRecurringTransfer] = useState<boolean>(false)

  const [transferType, setTransferType] = useState<TransferType>(
    stateOnNavigate?.isCheckSendPreSelected === true ? TransferType.CHECK : TransferType.SAMEDAY_ACH
  )

  useEffect(() => {
    if (transferType === TransferType.WIRE) {
      setIsRecurringTransfer(false)
    }
  }, [transferType])

  // If user selects WIRE and flips back to 'Into Amplify', we need to revert this back to ACH.
  useEffect(() => {
    if (selectedSourceAccount != null && !isAmplifyAccount(selectedSourceAccount)) {
      setTransferType(TransferType.SAMEDAY_ACH)
    }
  }, [selectedSourceAccount])

  // Transfer completion
  const [isTransferConfirmationModalOpen, setIsTransferConfirmationModalOpen] = useState(false)
  const [isRecurringTransferConfirmationModalOpen, setIsRecurringTransferConfirmationModalOpen] = useState(false)

  const [isTransferComplete, setIsTransferComplete] = useState(false)

  // Passcode verification
  const [isPasscodeVerificationModalOpen, setIsPasscodeVerificationModalOpen] = useState(false)
  const [isPasscodeVerified, setIsPasscodeVerified] = useState(false)

  // Wire Modal
  const [isWireDetailsModalOpen, setIsWireDetailsModalOpen] = useState<boolean>(false)

  // Recurring Transfer State
  const [recurringForm, setRecurringForm] = useState<RecurringRuleFormState>({
    dayOfWeek: 'Monday',
    dayOfMonth: 1,
    transferRuleType: TransferRuleType.DAILY,
    transferDate: getCurrentDate()
  })

  const [checkIssuanceForm, setCheckIssuanceForm] = useState<CheckIssuanceFormState>({
    areCounterpartyCheckFieldsValid: false
  })

  function handleAsyncTransferError (error: ApolloError): void {
    handleTransferError(getAsyncTransferErrorMessage(error, amount ?? null))
  }

  function handleTransferError (message: string): void {
    setTransferError(message)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  async function handleTransferCreation (): Promise<void> {
    const amplifyAccount = isAmplifyAccount(selectedSourceAccount ?? undefined)
      ? selectedSourceAccount
      : selectedDestinationAccount
    if (amplifyAccount?.amplifyAccount?.id == null) {
      handleTransferError('Please make sure that your Amplify Account is selected.'); return
    }
    const counterparty = isCounterparty(selectedSourceAccount ?? undefined)
      ? selectedSourceAccount
      : selectedDestinationAccount
    if (
      counterparty?.counterpartyId == null
    ) {
      handleTransferError('Please select a counterparty.'); return
    }

    // Validate amount
    if (amount == null) {
      handleTransferError('Amount is required.'); return
    }
    const amountAsNumber = Number(amount)
    if (
      amountAsNumber > (selectedSourceAccount?.liveBalance?.availableBalance?.amount ?? 0)
    ) {
      handleTransferError(createInsufficientFundsErrorMessage(amountAsNumber)); return
    }

    await createTransfer({
      variables: {
        input: {
          amount: amountAsNumber,
          amplifyAccountId: amplifyAccount.amplifyAccount?.id,
          counterpartyId: counterparty.counterpartyId,
          direction: isAmplifyAccount(selectedSourceAccount ?? undefined)
            ? TransferDirection.CREDIT
            : TransferDirection.DEBIT,
          type: transferType,
          checkMemo: checkIssuanceForm.memo,
          checkMessage: checkIssuanceForm.message
        }
      }
    })
  }

  function handleTransferSuccess (): void {
    setIsTransferComplete(true)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  function handleTransferInitiation (): void {
    // If the user is sending funds to an external counterparty, they have to verify their passcode
    if (
      !isCounterpartyTypeLinkedBankAccount(selectedDestinationAccount?.counterparty?.counterpartyType) &&
      !isAmplifyAccount(selectedDestinationAccount ?? undefined)
    ) {
      setIsPasscodeVerificationModalOpen(true)
    } else {
      openTransferConfirmationModal()
    }
  }

  function onPasscodeVerificationModalClose (): void {
    setIsPasscodeVerificationModalOpen(false)
    if (isPasscodeVerified) {
      // Setting timeout to avoid jumpy modal to modal transition
      setTimeout(() => {
        openTransferConfirmationModal()
      }, 200)
    }
  }

  function openTransferConfirmationModal (): void {
    // TODO - refactor the flow of passcode + wire + confirmation modals
    if (needsWireVerification(transferType, selectedDestinationAccount)) {
      setIsWireDetailsModalOpen(true)
    } else if (isRecurringTransfer) {
      setIsRecurringTransferConfirmationModalOpen(true)
    } else {
      setIsTransferConfirmationModalOpen(true)
    }
  }

  function onWireInfoSubmitted (newCounterpartyId: string): void {
    setIsWireDetailsModalOpen(false)
    // Refetch to pull the updated wire info to prevent re-prompting user for same info
    refetch()
    // Reset the selected counterparty to avoid counterparty from reverting to null
    setPreSelectedRecipientCounterpartyId(newCounterpartyId)
    setIsTransferConfirmationModalOpen(true)
  }

  function isFormValid (): boolean {
    const isMainFormValid = amount != null &&
      Number(amount) > 0 &&
      (
        isAmplifyAccount(selectedSourceAccount ?? undefined) ||
        isAmplifyAccount(selectedDestinationAccount ?? undefined)
      ) &&
      (
        selectedSourceAccount?.counterpartyId != null ||
        selectedDestinationAccount?.counterpartyId != null
      )
    if (!isMainFormValid) {
      return false
    }
    // By default, recurring transfers are only supported to accounts that user owns
    // Users can opt-in to enable recurring payments to vendors (managed by feature flag)
    // TODO Add more clear messaging in UI when recurring disabled due to external counterparty selection
    if (
      isRecurringTransfer &&
      selectedDestinationAccount?.counterparty?.counterpartyType !== CounterpartyType.PLAID &&
      !isRecurringVendorPaymentsEnabled
    ) {
      return false
    }

    // Verify that check fields are filled out when relevant
    if (
      transferType === TransferType.CHECK &&
      (checkIssuanceForm.memo == null || !checkIssuanceForm.areCounterpartyCheckFieldsValid)
    ) {
      return false
    }

    return true
  }

  if (isTransferComplete && amount != null) {
    return (
      <TransferSuccessComponent
        amount={Number(amount)}
        fromAccount={selectedSourceAccount ?? undefined}
        toAccount={selectedDestinationAccount ?? undefined}
        transferType={transferType}
      />
    )
  }

  const { title: transferButtonTitle, icon: transferButtonIcon } = getTransferButtonProps(transferType)

  return (
    <Flex flexDirection='column' width='100%' justifyContent='center' gap={6}>
      <ErrorBanner
        errorTitle='Problem Making Transfer'
        errorSubTitle={transferError}
      />
      <Heading color={Color.DARK_BLUE} size='md'>Make a Transfer</Heading>
      <Center flexDir='column' w='100%' gap={4}>
        <TransferAmountField
          amplifyAccount={amplifyAccount}
          amount={amount}
          setAmount={setAmount}
        />
        <TransferMethodSelector
          selectedSourceAccount={selectedSourceAccount}
          selectedTransferType={transferType}
          isCheckIssuanceEnabled={isCheckIssuanceEnabled}
          organizationCheckEnablementStatus={organizationCheckEnablementStatus}
          setSelectedTransferType={(type) => { setTransferType(type) }}
        />
        <Flex
          w='100%'
          gap={4}
          flexDir='column'
        >
          <AccountSelectorComponent
            headerText='From'
            altirAccountOptions={nonNull([amplifyAccount])}
            nonAltirAccountOptions={counterparties ?? []}
            onSelectedAccountChange={setSelectedSourceAccount}
            selectedAccount={selectedSourceAccount ?? undefined}
            selectedOppositeSideOfTransferAccount={selectedDestinationAccount}
            context={AccountSelectorContext.TRANSFER}
            accountContext={AccountSelectorAccountContext.FROM}
            transferType={transferType}
          />
          <AccountSelectorComponent
            headerText='To'
            altirAccountOptions={nonNull([amplifyAccount])}
            nonAltirAccountOptions={counterparties ?? []}
            onSelectedAccountChange={setSelectedDestinationAccount}
            selectedAccount={selectedDestinationAccount ?? undefined}
            selectedOppositeSideOfTransferAccount={selectedSourceAccount}
            context={AccountSelectorContext.TRANSFER}
            accountContext={AccountSelectorAccountContext.TO}
            transferType={transferType}
          />
        </Flex>
        {
          transferType === TransferType.CHECK && selectedDestinationAccount != null &&
            <CheckIssuanceFormSection
              formState={checkIssuanceForm}
              onStateUpdate={(state) => { setCheckIssuanceForm(state) }}
              selectedCounterpartyId={selectedDestinationAccount.counterpartyId ?? null}
            />
        }
        {
          transferType === TransferType.SAMEDAY_ACH &&
            <Flex gap={4} width='100%' padding={2}>
              <Radio
                size='lg'
                isChecked={isRecurringTransfer}
                colorScheme='selectableInput'
                onClick={() => { setIsRecurringTransfer(!isRecurringTransfer) }}
              />
              <Text> Make this a recurring transfer </Text>
            </Flex>
        }
        {
          isRecurringTransfer &&
            <RecurringRuleFormSection
              formState={recurringForm}
              setState={setRecurringForm}
            />
        }
      </Center>
      <Flex width='100%'>
        <Button
          text={transferButtonTitle}
          onClick={handleTransferInitiation}
          beforeIcon={transferButtonIcon}
          isDisabled={!isFormValid() || isTransferCreationLoading}
          isLoading={isTransferCreationLoading}
          size={ButtonSize.LARGE}
          onClickEventType={!isRecurringTransfer ? Event.INITIATE_TRANSFER_CLICK : Event.TRANSFER_PAGE_CREATE_RULE}
        />
      </Flex>
      <ConfirmTransferModal
        isOpen={isTransferConfirmationModalOpen}
        onClose={() => { setIsTransferConfirmationModalOpen(false) }}
        amount={Number(amount)}
        fromAccount={selectedSourceAccount ?? undefined}
        toAccount={selectedDestinationAccount ?? undefined}
        onConfirm={handleTransferCreation}
        isTransferCreationLoading={isTransferCreationLoading}
        transferType={transferType}
      />
      <TransferRuleConfirmationModal
        isModalOpen={isRecurringTransferConfirmationModalOpen}
        onModalClose={() => { setIsRecurringTransferConfirmationModalOpen(false) }}
        onUnhandledSubmissionError={() => {}}
        ruleData={{
          sourceAccount: selectedSourceAccount ?? undefined,
          destinationAccount: selectedDestinationAccount ?? undefined,
          transferRuleType: recurringForm.transferRuleType,
          transferAmount: amount,
          targetDate:
            recurringForm.transferRuleType === TransferRuleType.MONTHLY ? recurringForm.dayOfMonth : undefined,
          targetDay: recurringForm.transferRuleType === TransferRuleType.WEEKLY ? recurringForm.dayOfWeek : undefined,
          ruleStartDate: recurringForm.transferDate
        }}
      />
      <PasscodeVerificationModal
        isOpen={isPasscodeVerificationModalOpen}
        onClose={onPasscodeVerificationModalClose}
        onVerificationStatusChange={(isVerified) => { setIsPasscodeVerified(isVerified) }}
      />
      <AddWireCounterpartyModal
        isOpen={isWireDetailsModalOpen}
        onSuccess={(newCounterpartyId) => {
          onWireInfoSubmitted(newCounterpartyId)
        }}
        onClose={() => { setIsWireDetailsModalOpen(false) }}
        counterpartyId={selectedDestinationAccount?.counterpartyId ?? ''}
        achAccountNumber={selectedDestinationAccount?.achAccountNumber ?? ''}
        achRoutingNumber={selectedDestinationAccount?.achRoutingNumber ?? ''}
      />
    </Flex>
  )
}

function getTransferButtonProps (transferType: TransferType): { title: string, icon: ReactElement } {
  if (transferType === TransferType.CHECK) {
    return {
      title: 'Send Check',
      icon: <PaperPlaneIcon/>
    }
  }

  return {
    title: 'Make Transfer',
    icon: <TransferIconFilled color={Color.WHITE}/>
  }
}
