import React, { useState, type ReactElement } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import AddNewRecipientDetailsComponent, {
  type AddNewRecipientDetailsFormSubmissionData
} from './components/AddNewRecipientDetailsComponent'
import { type AddBankingDetailsFormData } from './components/banking_details/AddBankingDetailsForm'
import Page from '@/library/page/Page'
import { ContainerWidth } from '@/theme/theme'
import { addressInputToAddressUnderscoreInput } from '@/utils/addressUtils'
import { CREATE_EXTERNAL_COUNTERPARTY } from '@/graphql/mutations/CreateExternalCounterparty'
import {
  type CreateExternalCounterparty,
  type CreateExternalCounterpartyVariables
} from '@/graphql/__generated__/CreateExternalCounterparty'
import { getSuccessToast } from '@/utils/toastUtils'
import { type GetBusiness, type GetBusinessVariables } from '@/graphql/__generated__/GetBusiness'
import { GET_BUSINESS } from '@/graphql/queries/business/GetBusiness'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { useNavigationState } from '@/hooks/useNavigationState'
import { ROUTES } from '@/api/routes'
import {
  type CheckRecipientDetailsFormData
} from '@/components/pages/transfer/components/check_details/CheckRecipientDetailsForm'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'
import { type CheckParams, type WireParams } from '@/graphql/__generated__/globalTypes'

interface RecipientCreationPageNavigationState {
  isOriginTransferPage?: boolean
}

export default function RecipientCreationPage (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const [formSubmissionError, setFormSubmissionError] = useState<ErrorWithContent>()
  const toast = useToast()
  const navigate = useNavigate()

  const { isOriginTransferPage } = useNavigationState<RecipientCreationPageNavigationState>()
  const {
    data,
    loading,
    error
  } = useQuery<GetBusiness, GetBusinessVariables>(
    GET_BUSINESS,
    { variables: { franchiseGroupId: selectedFranchiseGroupId } }
  )

  const [
    createCounterparty,
    { loading: isSubmissionLoading }
  ] = useMutation<CreateExternalCounterparty, CreateExternalCounterpartyVariables>(
    CREATE_EXTERNAL_COUNTERPARTY,
    {
      onCompleted: (data) => {
        toast(getSuccessToast('Recipient created'))
        handleSuccessRedirect(data.createExternalCounterparty)
      },
      onError: (error) => {
        setFormSubmissionError({ error })
      }
    }
  )

  function onSubmit (formData: AddNewRecipientDetailsFormSubmissionData): void {
    const amplifyAccountId = data?.franchiseGroup?.amplifyAccount?.amplifyAccount?.id
    if (
      formData.bankingDetails?.accountNumber == null ||
        formData.bankingDetails?.routingNumber == null ||
        formData.bankingDetails?.accountType == null ||
        formData.bankingDetails?.counterpartyType == null ||
        formData.bankingDetails?.accountHolderName == null ||
        formData.bankingDetails?.fileUploadId == null ||
        amplifyAccountId == null
    ) {
      setFormSubmissionError({
        customContent: {
          title: ErrorCopy.FORM_UNABLE_TO_SUBMIT,
          subtitle: ErrorCopy.FORM_CHECK_FIELDS
        },
        error: Error()
      })
      return
    }

    void createCounterparty({
      variables: {
        accountNickname: formData.recipientNickname,
        accountHolderFullName: formData.bankingDetails?.accountHolderName,
        accountNumber: formData.bankingDetails?.accountNumber,
        routingNumber: formData.bankingDetails?.routingNumber,
        counterpartyType: formData.counterpartyType,
        documentLink: formData.bankingDetails?.fileUploadId,
        achSpecificFields: {
          accountType: formData.bankingDetails?.accountType,
          accountNumber: formData.bankingDetails?.accountNumber,
          routingNumber: formData.bankingDetails?.routingNumber
        },
        wireSpecificFields: parseWireInput(formData.bankingDetails),
        checkSpecificFields: parseCheckInput(formData.checkDetails),
        amplifyAccountId
      }
    })
  }

  function handleSuccessRedirect (counterpartyId: string): void {
    if (isOriginTransferPage === true) {
      navigate(ROUTES.TRANSFER, { state: { recipientCounterpartyId: counterpartyId } })
    } else {
      navigate(ROUTES.RECIPIENTS)
    }
  }

  function handleCancel (): void {
    if (isOriginTransferPage === true) {
      navigate(ROUTES.TRANSFER)
    } else {
      navigate(ROUTES.RECIPIENTS)
    }
  }

  // Rollout configuration
  const isCheckIssuanceEnabled = data?.currentUser?.rolloutConfiguration?.enableCheckIssuance === true

  return (
    <Page maxWidth={ContainerWidth.EXTRA_LARGE} marginY={12}>
      <AltirSkeleton isLoading={loading} error={error}>
        <AddNewRecipientDetailsComponent
          isSubmissionLoading={isSubmissionLoading}
          submissionError={formSubmissionError}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          isCheckIssuanceEnabled={isCheckIssuanceEnabled}
        />
      </AltirSkeleton>
    </Page>
  )
}

// For now, we only pass along the wire params if all the fields are complete
// TODO - add support for writing partial state in the backend
function parseWireInput (formData: AddBankingDetailsFormData): WireParams | undefined {
  if (formData.wireAccountNumber == null || formData.wireRoutingNumber == null ||
      formData.address == null || formData.bankAddress == null || formData.bankName == null
  ) {
    return undefined
  }

  const addressFormatted = addressInputToAddressUnderscoreInput(formData.address)
  if (addressFormatted == null) {
    return undefined
  }

  const bankAddressFormatted = addressInputToAddressUnderscoreInput(formData.bankAddress)
  if (bankAddressFormatted == null) {
    return undefined
  }

  return {
    accountNumber: formData.wireAccountNumber,
    routingNumber: formData.wireRoutingNumber,
    address: addressFormatted,
    bankAddress: bankAddressFormatted,
    bankName: formData.bankName
  }
}

function parseCheckInput (formData: CheckRecipientDetailsFormData | null): CheckParams | undefined {
  const addressFormatted = addressInputToAddressUnderscoreInput(formData?.address)
  if (formData?.name == null || addressFormatted == null) {
    return undefined
  }

  return {
    name: formData.name,
    address: addressFormatted
  }
}
