import { gql } from '@apollo/client'

export const BANK_ACCOUNT_DOCUMENT_FRAGMENT = gql`
    fragment BankAccountDocumentFragment on BankAccountDocument {
        id
        bankDocumentType
        documentStartPeriod
        documentEndPeriod
        url
    }
`
