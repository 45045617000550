import { gql } from '@apollo/client'
import { COUNTERPARTY_FRAGMENT } from './CounterpartyFragment'
import { AMPLIFY_ACCOUNT_FRAGMENT } from './AmplifyAccountFragment'
import { TRACKED_JOB_FRAGMENT } from '../TrackedJobFragment'

export const FINANCIAL_ACCOUNT_FRAGMENT = gql`
    ${COUNTERPARTY_FRAGMENT}
    ${AMPLIFY_ACCOUNT_FRAGMENT}
    ${TRACKED_JOB_FRAGMENT}
    fragment FinancialAccountFragment on FinancialAccount {
        id
        accountId
        counterpartyId
        franchiseGroupId
        name
        nameOfficial
        accountType
        accountSubTypeFormatted
        lastFour
        achAccountNumber
        achRoutingNumber
        wireAccountNumber
        wireRoutingNumber
        institution {
            name
            logoAssetUrl
        }
        transactionsUpdatedAt
        status
        connectionProvider
        plaidItemId
        plaidAccessToken
        counterparty {
            ...CounterpartyFragment
        }
        amplifyAccount {
            ...AmplifyAccountFragment
        }
        trackedJob {
            ...TrackedJobFragment
        }
    }
`
