import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Event } from 'metrics/metrics'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { Color, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'

import RightArrowIcon from '@/library/icons/RightArrowIcon'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { AuthResource } from '@/graphql/__generated__/globalTypes'
import { GET_USER_AUTHORIZATION } from '@/graphql/queries/user/GetUserAuthorization'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetUserAuthorizationVariables,
  type GetUserAuthorization
} from '@/graphql/__generated__/GetUserAuthorization'

interface NoAutomatedRuleTreasuryDropdownCardProps {
  counterpartyId?: string
}

export default function NoAutomatedRuleTreasuryDropdownCard (
  { counterpartyId }: NoAutomatedRuleTreasuryDropdownCardProps
): ReactElement {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { data, loading, error } = useQuery<GetUserAuthorization, GetUserAuthorizationVariables>(
    GET_USER_AUTHORIZATION,
    {
      variables: { organizationId } // Empty object ensures linter will pick up required variables in future
    }
  )
  const navigate = useNavigate()

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])

  // If no counterpartyId exists, the user won't be able to create a transfer rule.
  // Thus, we hide the card
  if (!loading && counterpartyId == null) {
    return <></>
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex direction='column' flex={7} gap={3}>
          <Text color={Color.DARK_BLUE}> No Automated Transfer Rules </Text>
          <Text whiteSpace='pre-line'>
            {'Save time by creating a time or balance-based rule\n to automatically move funds.'}
          </Text>
        </Flex>
        <Button
          text='Automate a Transfer'
          variant={ButtonVariant.PRIMARY}
          afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
          onClick={() => {
            navigate(ROUTES.CREATE_AUTOMATED_TRANSFER_RULE, { state: { counterparty: counterpartyId } })
          }}
          onClickEventType={Event.TREASURY_PAGE_CREATE_RULE}
          size={ButtonSize.SMALL}
          flex={3}
          isDisabled={!canTransfer}
          toolTipText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
        />
      </Flex>
    </AltirSkeleton>
  )
}
