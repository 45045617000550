import { type AddBankingDetailsFormData } from './AddBankingDetailsForm'
import {
  isInvalidAccountNumber,
  isInvalidAddress,
  isInvalidRoutingNumber,
  isInvalidWireAccountNumber
} from '@/utils/formUtils'
import { fieldRequiredMessage, isEmpty } from '@/utils/stringUtils'

/**
 * Returns true if all fields are valid.
 *
 * Leverages areRequiredFieldsFilled + performs additional validation on account/ routing numbers and addresses
 *
 * We define two separate functions here so that we can display robust error messaging when account numbers are invalid
 *   at time of submit, but we don't want to prevent form submission if the user enteres an invalid number
 */
export function isFormValidForSubmit (
  formData: AddBankingDetailsFormData,
  isWireFieldsVisible: boolean,
  onError: (fieldName: string, value: string | undefined) => void
): boolean {
  if (!areRequiredFieldsFilled(formData, isWireFieldsVisible, onError, { displayErrors: true })) {
    return false
  }

  let hasError = false
  const {
    isInvalid: isAccountNumberInvalid,
    reason: invalidAccountNumberReason
  } = isInvalidAccountNumber(formData.accountNumber)
  console.log(isAccountNumberInvalid, invalidAccountNumberReason)
  if (isAccountNumberInvalid) {
    onError('accountNumber', invalidAccountNumberReason)
    hasError = true
  }
  const {
    isInvalid: isRoutingNumberInvalid,
    reason: invalidRoutingNumberReason
  } = isInvalidRoutingNumber(formData.routingNumber)
  if (isRoutingNumberInvalid) {
    onError('routingNumber', invalidRoutingNumberReason)
    hasError = true
  }

  if (isWireFieldsVisible) {
    const {
      isInvalid: isWireAccountNumberInvalid,
      reason: invalidWireAccountNumberReason
    } = isInvalidWireAccountNumber(formData.wireAccountNumber)
    if (isWireAccountNumberInvalid) {
      onError('wireAccountNumber', invalidWireAccountNumberReason)
      hasError = true
    }
    const {
      isInvalid: isBankAddressInvalid,
      reason: bankAddressInvalidReason
    } = isInvalidAddress(formData.bankAddress ?? {})
    if (isBankAddressInvalid) {
      onError('bankAddress', bankAddressInvalidReason ?? fieldRequiredMessage('Bank Address'))
      hasError = true
    }
    const {
      isInvalid: isRecipientAddressInvalid,
      reason: recipientAddressInvalidReason
    } = isInvalidAddress(formData.address ?? {})
    if (isRecipientAddressInvalid) {
      onError('address', recipientAddressInvalidReason ?? fieldRequiredMessage('Recipient Address'))
      hasError = true
    }
  }
  return !hasError
}

/**
 * Returns true if all required fields have been filled.
 *
 * Used to disable the submit button
 */
export function areRequiredFieldsFilled (
  formData: AddBankingDetailsFormData,
  isWireFieldsVisible: boolean,
  onError: (fieldName: string, value: string | undefined) => void,
  { displayErrors = false } = {}
): boolean {
  let hasError = false
  if (isEmpty(formData.accountHolderName)) {
    displayErrors && onError('accountHolderName', fieldRequiredMessage('Account Holder Name'))
    hasError = true
  }
  if (isEmpty(formData.accountType)) {
    displayErrors && onError('accountType', fieldRequiredMessage('Account Type'))
    hasError = true
  }
  if (isEmpty(formData.accountNumber)) {
    displayErrors && onError('accountNumber', fieldRequiredMessage('Account Number'))
    hasError = true
  }
  if (isEmpty(formData.routingNumber)) {
    displayErrors && onError('routingNumber', fieldRequiredMessage('Routing Number'))
    hasError = true
  }
  if (isEmpty(formData.fileUploadId)) {
    displayErrors && onError('fileUploadId', fieldRequiredMessage('Document Upload'))
    hasError = true
  }

  if (isWireFieldsVisible) {
    if (isEmpty(formData.wireAccountNumber)) {
      displayErrors && onError('wireAccountNumber', fieldRequiredMessage('Wire Account Number'))
      hasError = true
    }
    if (isEmpty(formData.wireRoutingNumber)) {
      displayErrors && onError('wireRoutingNumber', fieldRequiredMessage('Wire Routing Number'))
      hasError = true
    }
    if (isEmpty(formData.bankName)) {
      displayErrors && onError('bankName', fieldRequiredMessage('Bank Name'))
      hasError = true
    }
    // if (isEmpty(formData.bankAddress?.streetAddressLine1)) {
    //   onError('bankAddress', fieldRequiredMessage('Bank Address'))
    //   hasError = true
    // }
    // if (isEmpty(formData.address?.streetAddressLine1)) {
    //   onError('address', fieldRequiredMessage('Recipient Address'))
    //   hasError = true
    // }
  }

  return !hasError
}
