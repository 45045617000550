/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function DisclosureIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M6.01392 2.08398H2.1875V17.564L13.2975 17.564V9.36732H8.01392C6.90934 9.36732 6.01392 8.47189 6.01392 7.36732V2.08398ZM7.07294 0.0857113C7.31672 0.100053 7.54764 0.203241 7.72127 0.376878L15.0046 7.66021C15.1921 7.84775 15.2975 8.1021 15.2975 8.36732V17.564C15.2975 18.6686 14.4021 19.564 13.2975 19.564H2.1875C1.08293 19.564 0.1875 18.6686 0.1875 17.564V2.08399C0.1875 0.979417 1.08293 0.0839844 2.1875 0.0839844H7.00606H7.01417C7.03382 0.0839844 7.05342 0.0845632 7.07294 0.0857113ZM8.01392 7.36732V3.4982L11.883 7.36732H8.01392Z'/>
      </svg>
    </IconContainer>
  )
}
