import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import RecipientsCardContent from './RecipientsCardContent'
import { Color, IconSize } from '@/theme/theme'
import {
  type GetRecentRecipientsVariables,
  type GetRecentRecipients
} from '@/graphql/__generated__/GetRecentRecipients'
import { GET_RECENT_RECIPIENTS } from '@/graphql/queries/GetRecipientsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import PersonIcon from '@/library/icons/PersonIcon'
import { ROUTES } from '@/api/routes'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function RecipientsCard (): ReactElement {
  const navigate = useNavigate()
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const {
    data,
    loading,
    error
  } =
    useQuery<GetRecentRecipients, GetRecentRecipientsVariables>(
      GET_RECENT_RECIPIENTS,
      {
        variables: {
          franchiseGroupId,
          counterpartyTypes: [
            CounterpartyType.EXTERNAL_VENDOR,
            CounterpartyType.EXTERNAL_PERSONAL
          ]
        },
        fetchPolicy: 'network-only'
      }
    )

  const externalVendors = data?.currentUser?.franchiseGroup?.counterparties ?? []
  // Display most recent 3 vendors in this card
  const recentExternalVendors = externalVendors.slice(0, 3)

  return (
    <AltirSkeleton
      isLoading={loading}
      error={error}
      width='100%'
    >
      <DropDownComponent
        title='My Recipients'
        icon={<PersonIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>}
        rightDescription={externalVendors.length > 0 ? `(${externalVendors.length})` : undefined}
        dropDownContent={
          <RecipientsCardContent
            recipients={recentExternalVendors}
            onAddNewRecipientClick={() => {
              navigate(ROUTES.RECIPIENTS_CREATE, { state: { isOriginTransferPage: true } })
            }}
          />
        }
      />
    </AltirSkeleton>
  )
}
