import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import TransferCreationFormComponent from './TransferCreationFormComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetTransferPageData,
  type GetTransferPageDataVariables
} from '@/graphql/__generated__/GetTransferPageData'
import { GET_TRANSFER_PAGE_DATA } from '@/graphql/queries/GetTransferPageData'
import PasscodeCreationModal from '@/library/modal/passcode/PasscodeCreationModal'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

export default function TransferCreationComponent (): ReactElement {
  const { franchiseGroupId, organizationId } = useAltirStore(state => {
    return {
      franchiseGroupId: state.selectedFranchiseGroupId,
      organizationId: state.selectedOrganizationState.selectedOrganizationId
    }
  })
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetTransferPageData, GetTransferPageDataVariables>(
    GET_TRANSFER_PAGE_DATA,
    {
      variables: {
        franchiseGroupId,
        organizationId
      },
      fetchPolicy: 'network-only'
    }
  )

  const amplifyAccount = data?.amplifyAccount ?? undefined
  const counterparties = data?.counterparties

  // Rollout
  const rolloutConfiguration = data?.currentUser?.rolloutConfiguration
  const isRecurringVendorPaymentsEnabled = rolloutConfiguration?.enableRecurringVendorPayments === true
  const isCheckIssuanceEnabled = rolloutConfiguration?.enableCheckIssuance === true

  const checkEnablementStatus = data?.currentUser?.selectedOrganization?.checkEnablementStatus ?? undefined
  const doesUserHavePasscode = data?.currentUser?.hasPasscode ?? undefined

  return (
    <AltirSkeleton isLoading={loading} error={error} width='100%'>
      {doesUserHavePasscode === false && <PasscodeCreationModal/>}
      <TransferCreationFormComponent
        amplifyAccount={amplifyAccount}
        counterparties={counterparties}
        isRecurringVendorPaymentsEnabled={isRecurringVendorPaymentsEnabled}
        isCheckIssuanceEnabled={isCheckIssuanceEnabled}
        organizationCheckEnablementStatus={checkEnablementStatus}
        refetch={refetch}
      />
    </AltirSkeleton>
  )
}
