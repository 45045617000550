import parse from 'url-parse'
import { nonNull } from './arrayUtils'
import { EMPTY_BALANCE_VALUE } from './constants'
import {
  type DerivedTransactionCategory, FileUploadType
} from '../graphql/__generated__/globalTypes'

export function safeString (value?: string | null): string {
  return value != null ? value : ''
}

export function isEmpty (value?: string | null): boolean {
  return value == null || value === ''
}

export function safeGetFirstLetter (str?: string | null): string {
  return str != null && str.length > 0 ? str[0] ?? '' : ''
}

export function getFormattedFullName (firstName?: string | null, lastName?: string | null): string {
  const divider = firstName != null && lastName != null ? ' ' : ''
  return `${firstName ?? ''}${divider}${lastName ?? ''}`
}

export function containsCharacters (str: string): boolean {
  return /[a-zA-Z]/g.test(str)
}

export function isValidPositiveNumberString (str: string): boolean {
  return /^[0-9.]+$/.test(str)
}

export function isValidNumberString (str: string): boolean {
  return /^-?[0-9.]*$/.test(str)
}

export function replaceEmptyStringWithUndefined (val: string): string | undefined {
  return val === '' ? undefined : val
}

export function joinStrings (strings: Array<string | undefined>, separator = ', '): string | undefined {
  const nonEmptyStrings = nonNull(strings)
  if (nonEmptyStrings.length < 1) {
    return nonEmptyStrings[0]
  }
  return nonEmptyStrings.join(separator)
}

export function fieldRequiredMessage (name: string): string {
  return `${name} is required.`
}

export function fieldInvalidMessage (name: string, message: string): string {
  return `${name} is invalid. ${message}`
}

/**
 * https://altir.app/dir/upload.txt -> upload.txt
 */
export function getFileNameFromUrl (url: string): string | undefined {
  const pathComponents = parse(url).pathname.split('/')
  return pathComponents[pathComponents.length - 1]
}

export type FileUploadDescription =
  'Bank Statement' |
  'Recipient Verification Document' |
  'Debt Schedule' |
  'Personal Financial Statement' |
  'Tax Return' |
  'Transaction Export' |
  'Other'

export function getFileUploadDescription (type: FileUploadType): FileUploadDescription {
  switch (type) {
    case FileUploadType.BANK_STATEMENT:
      return 'Bank Statement'
    case FileUploadType.BUSINESS_DEBT_SCHEDULE:
      return 'Debt Schedule'
    case FileUploadType.PERSONAL_FINANCIAL_STATEMENT:
      return 'Personal Financial Statement'
    case FileUploadType.TAX_RETURN:
      return 'Tax Return'
    case FileUploadType.COUNTERPARTY_VERIFICATION_EVIDENCE:
      return 'Recipient Verification Document'
    case FileUploadType.TRANSACTION_EXPORT:
      return 'Transaction Export'
    case FileUploadType.OTHER:
    case FileUploadType.GENERIC_LENDING_DOCUMENT:
      return 'Other'
  }
}

export function getFileUploadType (description: FileUploadDescription): FileUploadType {
  switch (description) {
    case 'Bank Statement':
      return FileUploadType.BANK_STATEMENT
    case 'Debt Schedule':
      return FileUploadType.BUSINESS_DEBT_SCHEDULE
    case 'Personal Financial Statement':
      return FileUploadType.PERSONAL_FINANCIAL_STATEMENT
    case 'Tax Return':
      return FileUploadType.TAX_RETURN
    case 'Transaction Export':
      return FileUploadType.TRANSACTION_EXPORT
    case 'Recipient Verification Document':
      return FileUploadType.COUNTERPARTY_VERIFICATION_EVIDENCE
    case 'Other':
      return FileUploadType.GENERIC_LENDING_DOCUMENT
  }
}

// Converts bytes to formatted string, i.e 1024 -> 1KB
export function formatBytes (bytes: number): string {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const sizes = [' bytes', 'kb', 'mb', 'gb', 'tb']

  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(k))))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))}${sizes[i] ?? ' bytes'}`
}

export function getCurrencyChangeFormatted (
  amount: number,
  formatted: (amount: number) => string = getCurrencyFormatted,
  currency = 'USD'
): string {
  const positiveChangeCharacter = amount > 0 ? '+' : ''
  return `${positiveChangeCharacter}${formatted(amount)}`
}

export function getCurrencyFormatted (
  amount?: number | null,
  { currency = 'USD', minimumFractionDigits = 0 } = { minimumFractionDigits: 2, currency: 'USD' }
): string {
  if (amount == null) {
    return EMPTY_BALANCE_VALUE
  }
  return amount.toLocaleString('en-US', {
    style: 'currency',
    minimumFractionDigits,
    maximumFractionDigits: 2,
    currency
  })
}

export function getPercentageFormatted (
  rate: number | null,
  { fractionDigits = 2 } = { fractionDigits: 2 }
): string {
  if (rate === null) {
    return EMPTY_BALANCE_VALUE
  }
  return rate.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })
}

enum DerivedTransactionCategoryFormatted {
  // "Outgoing"
  COGS = 'Cost of Goods Sold',
  ROYALTY = 'Royalty',
  PAYROLL = 'Payroll',
  RENT = 'Rent',
  UTILITIES = 'Utilities',
  OTHER_OPERATING_EXPENSE = 'Other Operating Expense',
  CC_EXPENSE = 'Credit Card Payment',
  FINANCING_PAYMENT = 'Financing Payment',
  TAX_EXPENSE = 'Tax Expense',
  LOAN_REPAYMENT = 'Loan Repayment',
  CAPITAL_INVESTMENT = 'Capital Investment',
  OTHER_TRANSFER = 'Other Transfer',
  // TODO (PJ): Improve naming once we think through new set of labels
  INTERNAL_TRANSFER = 'Manual Transfer',
  EXTERNALLY_INITIATED_TRANSFER = 'External Transfer', // Deprecated
  EXTERNALLY_INITIATED_DEPOSIT = 'External Deposit',
  EXTERNALLY_INITIATED_WITHDRAWAL = 'External Withdrawal',
  UNKNOWN = 'Uncategorized',

  // "Incoming"
  POS_INCOME = 'Point of Sale Income',
  THIRD_PARTY_INCOME = 'Third Party Income',
  OTHER_DEPOSIT = 'Other Deposit',
  FINANCING_DEPOSIT = 'Financing Deposit',

  SALES_TAX = 'SALES_TAX',
  SERVICE_FEE = 'SERVICE_FEE',
  CATERING_INCOME = 'CATERING_INCOME',
  DELIVERY_INCOME = 'DELIVERY_INCOME',
  ERROR = 'ERROR',
  EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER',
  OTHER_INCOME = 'OTHER_INCOME',

  // "Automated Transfers"
  AUTOMATED_INTERNAL_TRANSFER = 'Automated Transfer'
}

export function getCategoryLabelFormatted (
  categoryLabel: DerivedTransactionCategory
): DerivedTransactionCategoryFormatted {
  return DerivedTransactionCategoryFormatted[categoryLabel]
}

// Adapted from https://github.com/validatorjs/validator.js/blob/master/src/lib/isAbaRouting.js
export function isValidRoutingNumber (str: string): boolean {
  const isRoutingReg = /^(?!(1[3-9])|(20)|(3[3-9])|(4[0-9])|(5[0-9])|(60)|(7[3-9])|(8[1-9])|(9[0-2])|(9[3-9]))[0-9]{9}$/

  if (!isRoutingReg.test(str)) return false

  let checkSumVal = 0
  for (let i = 0; i < str.length; i++) {
    const digit = Number(str[i])
    if (isNaN(digit)) return false // If the digit is not a number, return false

    if (i % 3 === 0) checkSumVal += digit * 3
    else if (i % 3 === 1) checkSumVal += digit * 7
    else checkSumVal += digit * 1
  }
  return (checkSumVal % 10 === 0)
}
