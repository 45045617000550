import { useQuery } from '@apollo/client'
import { Flex, Heading, Link } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import TransferTableComponent from './TransferTableComponent'
import { ROUTES } from '@/api/routes'
import Button, { ButtonVariant } from '@/library/button/Button'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { Color } from '@/theme/theme'
import { GET_TRANSFER_HISTORY } from '@/graphql/queries/GetTransferHistory'
import { type GetTransferHistoryVariables, type GetTransferHistory } from '@/graphql/__generated__/GetTransferHistory'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export interface RecentTransfersComponentProps {
  numberOfTransfers?: number
}

export default function RecentTransfersComponent ({
  numberOfTransfers = 10
}: RecentTransfersComponentProps): ReactElement {
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const navigate = useNavigate()
  const {
    loading: transferHistoryLoading,
    data: transferHistoryData,
    error
  } = useQuery<GetTransferHistory, GetTransferHistoryVariables>(GET_TRANSFER_HISTORY, {
    skip: franchiseGroupId == null,
    variables: {
      franchiseGroupId
    }
  })

  const recentTransfers = (transferHistoryData?.transfers ?? [])
    .slice(0, numberOfTransfers)

  return (
    <AltirSkeleton isLoading={transferHistoryLoading} error={error}>
      <Flex flexDir='column' gap={4}>
        <Flex flexDir='column' gap={6}>
          <Flex justifyContent='space-between'>
            <Heading color={Color.DARK_BLUE} size='md'>Activity</Heading>
          </Flex>
          <TransferTableComponent transfers={recentTransfers} isCompact={true}/>
        </Flex>
        <Flex w='100%'>
          <Link
            color={Color.GREY_BLUE}
            onClick={() => { navigate(ROUTES.TRANSFER_HISTORY) }}
            w='100%'
          >
            <Button
              text='View All'
              variant={ButtonVariant.WHITE_OUTLINE}
              afterIcon={<RightArrowIcon/>}
              onClickEventType={Event.VIEW_ALL_TRANSFERS_CLICK}
            />
          </Link>
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
