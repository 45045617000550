import { useMutation } from '@apollo/client'
import { useState } from 'react'
import useTrackJobStatus from './useTrackJobStatus'
import {
  type ExportJobStatus_exportJob
} from '@/graphql/__generated__/ExportJobStatus'
import { CREATE_EXPORT_JOB } from '@/graphql/mutations/CreateExportJob'
import { type CreateExportJob, type CreateExportJobVariables } from '@/graphql/__generated__/CreateExportJob'

export interface UseFileExportHookStates {
  createExportJob: (exportJobMutationVariables?: CreateExportJobVariables) => void
  fileDownloadUrl?: string
  error?: Error
  loading: boolean
}

export interface UseFileExportProps {
  defaultExportJobMutationVariables?: CreateExportJobVariables
  onSuccess?: (fileUrl: string) => void
  onError?: (error: Error) => void
}

/**
 * This hook triggers a mutation to create a File Export Job and the polls the jobId
 * until it reaches a Non-Pending state
 * @param defaultExportJobMutationVariables Variables required to create the export job
 * @param onSuccess Optional callback param that is triggered on a successful export job
 * @param onError Optional callback param that is triggered on an Unsuccessful export job
 * @returns
 *   createExportJob: this triggers the mutation to begin
 *   fileDownloadUrl: the url through which the resultant export job can be accessed
 *   error: error state
 *   loading: loading state
 */
export default function useFileExport ({
  defaultExportJobMutationVariables,
  onSuccess,
  onError
}: UseFileExportProps): UseFileExportHookStates {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string | undefined>(undefined)

  const { trackJobStatus } = useTrackJobStatus({
    onSuccess: handleSuccess,
    onError: handleError
  })

  // Issue Mutation to create the file export job
  const [initiateExportJob] = useMutation<CreateExportJob, CreateExportJobVariables>(
    CREATE_EXPORT_JOB, {
      variables: defaultExportJobMutationVariables,
      onError: (error) => { handleError(error) },
      onCompleted: (data) => {
        trackJobStatus(data?.export.id)
      }
    })

  function handleSuccess (exportJob?: ExportJobStatus_exportJob): void {
    if (exportJob?.fileUrl == null) {
      setError(Error('Null file Url received'))
      return
    }
    setFileDownloadUrl(exportJob?.fileUrl)
    setLoading(false)
    if (onSuccess != null) onSuccess(exportJob?.fileUrl)
  }

  function handleError (error: Error): void {
    setError(error)
    setLoading(false)
    if (onError != null) onError(error)
  }

  function createExportJob (exportJobMutationVariables?: CreateExportJobVariables): void {
    setLoading(true)
    void initiateExportJob({ variables: exportJobMutationVariables })
  }
  return { createExportJob, fileDownloadUrl, error, loading }
}
