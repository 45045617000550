import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import HeaderStatistic from '../text/HeaderStatistic'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface FinancialAccountBalanceDisplayComponentProps {
  availableBalance: number | null
  currentBalance: number | null
  isAccountClosed?: boolean
}

export default function FinancialAccountBalanceDisplayComponent ({
  availableBalance,
  currentBalance,
  isAccountClosed = false
}: FinancialAccountBalanceDisplayComponentProps): ReactElement {
  return (
    <Flex gap={8} alignItems='start'>
      <HeaderStatistic
        description='Available Balance'
        descriptionInfoText='Funds you have access to right now'
        statistic={getCurrencyFormatted(availableBalance)}
        isDisabled={isAccountClosed}
      />
      <HeaderStatistic
        description='Current Balance'
        statistic={getCurrencyFormatted(currentBalance)}
        isDisabled={isAccountClosed}
      />
    </Flex>
  )
}
