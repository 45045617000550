import { Box, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import InstitutionLogo from '../logo/InstitutionLogo'
import RecurringRuleBalanceIcon from '../icons/RecurringRuleBalanceIcon'
import RecurringRuleTimeIcon from '../icons/RecurringRuleTimeIcon'
import TrackedJobLoader from '../loading/TrackedJobLoader'
import Badge, { BadgeVariant } from '../badge/Badge'
import {
  type FinancialAccountExtendedFragment as FinancialAccount
} from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import {
  AccountLinkStatus,
  AmplifyAccountStatus,
  LinkedAccountType,
  TrackedJobType
} from '@/graphql/__generated__/globalTypes'
import {
  BorderRadius,
  Color,
  Opacity
} from '@/theme/theme'
import { isBalanceRule } from '@/utils/transferRuleUtils'
import { getMask } from '@/utils/financialAccountUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface FinancialAccountRowProps {
  account: FinancialAccount
  hasPendingRefreshJob: boolean
  isTransactionRefreshEnabled: boolean
}

export default function FinancialAccountRow (
  {
    account,
    hasPendingRefreshJob
  }: FinancialAccountRowProps
): ReactElement {
  const isLoginRequired = account.status === AccountLinkStatus.LOGIN_REQUIRED
  const isBank = account.accountType === LinkedAccountType.BANK
  const isAccountClosed = account.amplifyAccount?.status === AmplifyAccountStatus.CLOSED
  const opacity = isLoginRequired ? Opacity.INACTIVE : Opacity.ACTIVE

  const transferRule = account.transferRules[0]

  const displayedAccountBalance = isBank
    ? getCurrencyFormatted(account.liveBalance?.availableBalance?.amount)
    : getCurrencyFormatted(account.liveBalance?.currentBalance?.amount)

  return (
    <Flex
      justifyContent='space-between'
      grow={1}
      borderRadius={BorderRadius.CARD}
      alignItems='center'
    >
      <Flex flex={4} align='flex-start' alignItems='center' opacity={opacity}>
        {
          hasPendingRefreshJob
            ? <TrackedJobLoader type={TrackedJobType.TRANSACTION_REFRESH}/>
            : <InstitutionLogo
                src={account.institution?.logoAssetUrl ?? undefined}
                height='6'
              />
        }
        <Box flex={1}>
          <Text color={Color.DARK_GREY} px={3}>
            {getMask(account.lastFour)}
          </Text>
        </Box>
      </Flex>
      <Box flex={6} opacity={opacity}>
        <Text color={Color.DARK_BLUE} px={3} noOfLines={1}>
          {account.name}
        </Text>
      </Box>
      <Box flex={6} opacity={opacity}>
        <Text color={Color.DARK_BLUE} px={3} noOfLines={1}>
          {account.accountSubTypeFormatted}
        </Text>
      </Box>
      <Box flex={2} opacity={opacity}>
        {
          isAccountClosed &&
            <Badge title='Closed' badgeVariant={BadgeVariant.DARK_GREY}/>
        }
      </Box>
      <Flex flex={2} align='center' justifyContent='flex-end' opacity={opacity}>
        {
          transferRule != null && isBalanceRule(transferRule.transferRuleType) &&
            <RecurringRuleBalanceIcon color={Color.DARK_BLUE}/>
        }
        {
          transferRule != null && !isBalanceRule(transferRule.transferRuleType) &&
            <RecurringRuleTimeIcon color={Color.DARK_BLUE}/>
        }
      </Flex>
      <Flex flex={3} align='center' justifyContent='flex-end' opacity={opacity}>
        <Text color={hasPendingRefreshJob ? Color.DARK_GREY : Color.DARK_BLUE} textAlign='end' >
          {displayedAccountBalance}
        </Text>
      </Flex>
    </Flex>
  )
}
