import { gql } from '@apollo/client'

export const GET_BANK_ACCOUNT_DOCUMENT_SIGNED_URL = gql`
    query GetBankAccountDocumentSignedUrl(
        $id: String!, 
    ) {
        bankAccountDocument(id: $id) {
            id
            signedUrl
        }
    }
`
