import { DateTime } from 'luxon'
import { type DateSelectionWindow, DateWindowSelectionOptions } from '@/types/types'

export enum TimeZone {
  DEFAULT_USER_TIME_ZONE = 'America/New_York',
  DEFAULT_SYSTEM_TIME_ZONE = 'utc',
}
export const DEFAULT_TIME_ZONE_SUFFIX = 'ET'
export const TIME_ZONE_SUFFIX_MAP: Record<string, string> = {
  'America/New_York': 'ET',
  'America/Chicago': 'CT',
  'America/Denver': 'MT',
  'America/Los_Angeles': 'PT'
}

export enum DateTimeTemplate {
  MONTH_YEAR = 'MMMM yyyy',
  MONTH_LONG = 'MMMM',
  YEAR_LONG = 'yyyy',
  MONTH_YEAR_SHORT = 'M/d',
  FULL = 'MM/dd/yyyy',
  MM_DD_YY_SLASH = 'MM/dd/yy',
  LONG = 'LLLL d, yyyy',

  // This is only for backwards compatibility, do not use going forward
  M_D_YY = 'M/d/yy'
}

export enum DayOfWeek {
  MON = 1,
  TUE = 2,
  WED = 3,
  THUR = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7
}

/**
  * We currently support contiguous US time zones,
  * otherwise we default to America/New_York
  */
export function getUserTimeZone (): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const supportedTimeZones = Object.keys(TIME_ZONE_SUFFIX_MAP)
  return supportedTimeZones.includes(timeZone) ? timeZone : TimeZone.DEFAULT_USER_TIME_ZONE
}

export function getUserTimezoneSuffix (timeZone: string): string {
  return TIME_ZONE_SUFFIX_MAP[timeZone] ?? DEFAULT_TIME_ZONE_SUFFIX
}

export function formatEpochTime (epochTime: number): string | null {
  const date = new Date(epochTime * 1000).toISOString()
  return getFormattedDateString(date)
}

/**
 * Parses DateTime ISO returned by the backend. By default, the backend returns
 * Date objects in UTC time.
 */
export function parseDate (date: any, timeZone = 'utc'): DateTime {
  return DateTime.fromISO(date, { zone: timeZone })
}

export function dateTimeFromJsDate (date?: Date | null): DateTime | null {
  if (date == null) return null
  return DateTime.fromJSDate(date)
}

export function jsDateFromDateTime (dateTime: DateTime | undefined): Date | null {
  if (dateTime == null) return null

  return dateTime.toJSDate()
}

/**
 * When displaying date strings, we default to ET
 * if the user is outside of the contiguous US.
 */
export function getFormattedTimeString (date: any | null): string | null {
  if (date == null) {
    return null
  }
  const timeZone = getUserTimeZone()
  const timeZoneSuffix = getUserTimezoneSuffix(timeZone)
  const timeString: string = parseDate(date, timeZone).toFormat('h:mma')
  return `${timeString.toLowerCase()} ${timeZoneSuffix}`
}

export function getFormattedDateString (
  date: any | null,
  template: DateTimeTemplate = DateTimeTemplate.FULL,
  timeZone?: string
): string | null {
  if (date == null) {
    return null
  }
  const userTimeZone = getUserTimeZone()
  return parseDate(date, timeZone ?? userTimeZone).toFormat(template)
}

export function getISODateOrUndefined (dateTime: DateTime): string | undefined {
  return dateTime.toISODate() ?? undefined
}

export function getISODateOrThrow (dateTime: DateTime): string {
  const isoDate = dateTime.toISODate()
  if (isoDate == null) {
    throw Error('Invalid Date')
  }
  return isoDate
}

export function dateTimeFromDateTimeTemplate (dateString: string, template: DateTimeTemplate): DateTime {
  return DateTime.fromFormat(dateString, template, { zone: 'UTC' })
}

export function dateTimeToDateTimeTemplate (dateTime: DateTime, template: DateTimeTemplate): string {
  return dateTime.toFormat(template)
}

export function getCurrentDateTimeInUserTimeZone (): DateTime {
  const timeZone = getUserTimeZone()
  return newDate().setZone(timeZone)
}

export function getCurrentDate (): string {
  const timeZone = getUserTimeZone()
  return newDate().setZone(timeZone).toFormat(DateTimeTemplate.FULL)
}

/*
 * Converts to an iso-string, ex 2024-04-11T00:00:00.000-04:00
 * Returns null if there is an error
 */
export function dateTimeToISOString (dateTime: DateTime): string | null {
  return dateTime.toISO()
}

export function beginningOfTime (timeZone: string = TimeZone.DEFAULT_SYSTEM_TIME_ZONE): DateTime {
  return DateTime.fromMillis(0, {}).setZone(timeZone)
}

export function isBeginningOfTime (date: DateTime): boolean {
  return beginningOfTime().equals(date)
}

export function getDateWindow (window: DateWindowSelectionOptions): DateSelectionWindow {
  const now = newDate()
  switch (window) {
    case DateWindowSelectionOptions.WEEK:
      return { startDate: now.minus({ week: 1 }), endDate: now }
    case DateWindowSelectionOptions.MONTH:
      return { startDate: now.minus({ month: 1 }), endDate: now }
    case DateWindowSelectionOptions.THREE_MONTH:
      return { startDate: now.minus({ month: 3 }), endDate: now }
    case DateWindowSelectionOptions.YEAR:
      return { startDate: now.minus({ year: 1 }), endDate: now }
    case DateWindowSelectionOptions.YEAR_TO_DATE:
      return { startDate: now.startOf('year'), endDate: now }
    case DateWindowSelectionOptions.ALL:
      return { startDate: beginningOfTime(), endDate: now }
  }
}

// Returns a new date in the users timezone (different to the backend which operates on UTC)
export function newDate (): DateTime {
  return DateTime.now()
}

export function dateTimeToISODate (dateTime: DateTime): string {
  const isoDateString = newDate().toISODate()
  if (isoDateString == null) {
    throw Error(`Unable to convert ISO string encountered: ${dateTime.invalidReason ?? ''}`)
  }
  return isoDateString
}

export function isFutureDate (date: DateTime): boolean {
  return date > newDate()
}

/*
* Return true if the provided date occurs before HH:MM.
*/
export function dateIsBeforeTime (date: DateTime, targetTime: { hour: number, minute: number }): boolean {
  return date.hour < targetTime.hour || (date.hour === targetTime.hour && date.minute < targetTime.minute)
}

export function getTimeSinceDateFormatted (date: DateTime): string {
  const now = newDate()
  const diffMinutes = Math.floor(now.diff(date, 'minutes').minutes)

  // Display difference in minutes
  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`
  }

  // Display difference in hours
  if (diffMinutes < (60 * 24)) {
    const diffHours = Math.floor(now.diff(date, 'hours').hours)
    return `${diffHours} hours ago`
  }

  // Display difference in days
  const diffDays = Math.floor(now.diff(date, 'days').days)
  return `${diffDays} days ago`
}
